import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const Cart = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { items } = useSelector(state => state.cart);
  const itemCount = items.length;

  const [isAnimating, setIsAnimating] = React.useState(false);
  const [showTooltip, setShowTooltip] = React.useState(false);

  const prevItemCount = React.useRef(itemCount);
  const shouldAnimate = itemCount > prevItemCount.current && prevItemCount.current > 0 && !isAnimating;

  React.useEffect(() => {
    if (shouldAnimate) {
      setIsAnimating(true);

      setTimeout(() => {
        setIsAnimating(false);
      }, 300);
    }

    if (itemCount > prevItemCount.current) {
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 1500)
    }

    prevItemCount.current = itemCount;
  }, [itemCount, shouldAnimate]);

  return (
    <motion.div
      className="relative flex items-center gap-2.5"
      onClick={() => pathname !== '/cart' && navigate('/cart')}
    >
      <motion.img
        // key={itemCount}
        layout
        src="/icons/nav/basket.svg"
        alt="lemon"
        initial={{ scale: 1 }}
        animate={shouldAnimate ? { scale: [1, 2, 1] } : { scale: 1 }}
        transition={shouldAnimate ? { duration: 0.3, ease: 'easeInOut' } : {}}
        exit={{ scale: 1 }}
        className="w-6 h-6"
      />

      <AnimatePresence>
        {itemCount > 0 &&
          <motion.div
            initial={{ y: -15, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { duration: 0.2 } }}
            exit={{ opacity: 0 }}
            className='absolute top-1 right-0 h-2 w-2 bg-red-500 rounded-full' />
        }
      </AnimatePresence>

      {/* {itemCount > 0 && (
        <motion.div
          initial={{ opacity: 0, x: -10 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 10 }}
          transition={{ type: 'spring', stiffness: 300, damping: 20 }}
          className="text-sm font-semibold text-white w-4 mt-0.5"
        >
          ({itemCount})
        </motion.div>
      )} */}

      <AnimatePresence>
        {showTooltip && (
          <motion.div
            initial={{ right: '-100%', opacity: 0 }}
            animate={{ right: '0%', opacity: 1 }}
            exit={{ right: '-100%', opacity: 0 }}
            transition={{ type: 'spring', bounce: 0, duration: 0.5 }}
            className="absolute top-[2rem] right-0 bg-white text-black text-xs rounded p-1 shadow-lg min-w-fit px-4"
          >
            <div className='w-full h-full relative'>
              <p className='whitespace-nowrap'>Adicionado ao carrinho</p>
              {/* <div className="absolute -top-2 right-[18px] w-0 h-0 border-l-4 border-l-transparent border-r-4 border-r-transparent border-b-4 border-b-white"></div> */}
              <div className="absolute -top-2 -right-[7px] w-0 h-0 border-l-4 border-l-transparent border-r-4 border-r-transparent border-b-4 border-b-white"></div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

    </motion.div>
  )
}

export default Cart