import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Card from 'components/ui/Card';
import Button from 'components/ui/Button';
import LatestRequestCard from 'components/cards/LatestRequestCard';
import HorizontalCarousel, { CarouselSkeleton } from 'components/common/HorizontalCarousel';

import { getProductCategories, getServiceCategories } from 'store/category';
import { getRequests, getRequestsHistory } from 'store/request';

import NumberFlow from '@number-flow/react';
import useMeasure from 'react-use-measure';


const Homepage = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { user } = useSelector(state => state.auth);
  const { products, services } = useSelector(state => state.category);
  const { history, list: requests } = useSelector(state => state.request);

  useEffect(() => {
    if (!products) dispatch(getProductCategories());
    if (!services) dispatch(getServiceCategories());

    if (user && !history) dispatch(getRequestsHistory(user.id))
    if (user && !requests) dispatch(getRequests(user.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // const availableProductFilters = [];
  // console.log('availableProducts: ', availableProducts);

  // const availableServiceFilters = [];
  // console.log('availableServices: ', availableServices);

  const availableProducts = requests ? requests.filter(p => p.purchase.product).length : 0;
  const availableServices = requests ? requests.filter(p => p.purchase.service).length : 0;

  return (
    <div className="flex flex-col h-full w-full px-8 space-y-6">
      <div className='grid grid-cols-6 gap-5'>
        <div className='col-span-3'>
          <LatestRequestCard />
        </div>
        <div className='col-span-3 grid grid-cols-1 gap-3'>
          <div className='h-full [&>*]:w-full'>
            <AvailableType value={availableProducts ?? 0} title={t('service.label_other')} icon="clean_washer.svg" to='/purchases?tab=service' />
          </div>

          <AvailableType value={availableServices ?? 0} title={t('product.label_other')} icon="clean_clothes.svg" to='/purchases?tab=product' />
        </div>
      </div>

      <div className='flex flex-col gap-10 overflow-y-auto'>
        <div className="flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <span className="text-lg font-semibold">
                {t('service.label', { count: 2 })}
              </span>
              <span className="text-xs text-secondary">
                {t('home.text05')}
              </span>
            </div>
            <div>
              <Button
                text={t('general.see-all')}
                size="sm"
                onClick={() => navigate('/services')}
              />
            </div>
          </div>
          <div>
            {!services
              ? <CarouselSkeleton />
              : <HorizontalCarousel items={services} itemType="service" />
            }
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <span className="text-lg font-semibold">
                {t('product.label', { count: 2 })}
              </span>
              <span className="text-xs text-secondary">
                {t('home.text06')}
              </span>
            </div>
            <div>
              <Button
                text={t('general.see-all')}
                size="sm"
                onClick={() => navigate('/products')}
              />
            </div>
          </div>
          <div>
            {!products
              ? <CarouselSkeleton />
              : <HorizontalCarousel items={products} itemType="product" />
            }
          </div>
        </div>

        {/* <div className='flex justify-between items-end'>
          <div className="flex flex-col">
            <span className="text-lg font-semibold">{t('home.myPurchases')}</span>
            <span className="text-xs text-secondary">{t('home.useServicesPacks')}</span>
          </div>
          <div className='w-fit'>
            <Button
              className="px-2"
              isDisabled={!user}
              icon='lucide:arrow-right'
              size='sm'
              onClick={() => navigate('/purchases')}
            />
          </div>
        </div>

        <div className='flex justify-between items-end mb-8'>
          <div className="flex flex-col">
            <span className="text-lg font-semibold">{t('home.viewRequests')}</span>
            <span className="text-xs text-secondary">{t('home.accompanyRequests')}</span>
          </div>
          <div className='w-fit'>
            <Button
              className="px-2"
              isDisabled={!user}
              size='sm'
              icon='lucide:arrow-right'
              onClick={() => navigate('/requests')}
            />
          </div>
        </div> */}

      </div>
    </div>
  )
}

const AvailableType = ({ value, title, icon, to }) => {

  const navigate = useNavigate();

  return (
    <Card
      layout
      onClick={() => navigate(to)}
      className='h-full'
    >
      <div className='flex h-full items-center gap-3'>
        <img src={"/icons/" + icon} alt={icon} />
        <div className='flex flex-col gap-1'>
          <p className='text-xs font-medium'>{title}</p>
          <span className='text-xs font-light text-secondary'>
            <NumberFlow format={{ notation: 'standard' }} value={value} />
            &nbsp;por usar</span>
        </div>
      </div>
    </Card>
  )
}


export default Homepage;