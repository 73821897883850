import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import cn from 'classnames';
import moment from 'moment';
import stateColors from 'config/stateColors';

import Badge from 'components/ui/Badge';
import Card from 'components/ui/Card';
import Icon from 'components/ui/Icon';

const HistoryCard = ({ history }) => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className='pb-4'>
      <Card className='py-4 px-5'>
        <div className='flex flex-col'>
          <p className='font-semibold'>{history.purchase.product ? history.purchase.product.category : history.purchase.service.category}</p>
          <p className='text-sm font-medium'>{history.purchase.product ? history.purchase.product.name : history.purchase.service.name}</p>
        </div>
        <div className='mt-1'>
          <div className='flex items-end  justify-between'>
            <div className='flex flex-col gap-x-0.5 text-zinc-400'>
              <p className='text-xs'>{t('request.change-at')}</p>
              <p className='text-xs'>{moment(history.changeAt, 'DD-MM-YYYY HH:mm').format('D [de] MMMM YYYY [às] HH:mm')}</p>
            </div>
            <Badge className={cn("h-fit text-white", stateColors[history.state])}>
              {t(`request.states.${history.state}`)}
            </Badge>
          </div>
        </div>
      </Card>
      <div className='w-full bg-black px-4 py-4 flex flex-col text-white rounded-lg rounded-tr-none'>
        <div className='flex justify-between'
          onClick={() => navigate('/request/details/' + history.id)}
        >
          <p>Detalhes do Pedido</p>
          <Icon icon='lucide:chevron-right' />
        </div>
      </div>
    </div>
  )
}

export default HistoryCard;