import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from 'helpers/api'
import { toast } from "react-toastify";
import { t } from 'i18next';

export const purchase = createAsyncThunk('purchase', async (params, { dispatch }) => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };

  try {
    const response = await api.post("screen02/purchase", params, config);
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

const initialState = {
  identifier: false,
  payment: false,
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    updatePayment(state, action) {
      state.payment = action.payload;
    },
    updateIdentifier(state, action) {
      state.identifier = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(purchase.fulfilled, (state, action) => {
      state.identifier = action.payload.Data?.transaction?.identifier
    })
  }
});

export const { updatePayment, updateIdentifier } = paymentSlice.actions;

export default paymentSlice.reducer;
