import React from "react";
import { motion } from 'framer-motion';

const Collapse = ({ children, open, duration }) => {
  return (
    <motion.div
      initial={{ height: 0 }}
      exit={{ height: 0 }}
      animate={{
        height: open ? 'auto' : 0,
      }}
      transition={{ duration: duration }}
      className="overflow-hidden"
    >
      {children}
    </motion.div>
  );
};

export default Collapse;
