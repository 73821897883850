import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  quantity: 0,
  amount: 0,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItemToCart(state, action) {
      const newItem = action.payload;
      const existingItem = state.items.find(item => item.id === newItem.id && item.type === newItem.type);
      state.quantity++;
      if (!existingItem) {
        state.items.push({
          id: newItem.id,
          price: newItem.price,
          quantity: 1,
          max: newItem.quantity,
          totalPrice: newItem.price,
          name: newItem.name,
          type: newItem.type,
          category: newItem.category,
        });
      } else {
        existingItem.quantity++;
        existingItem.totalPrice += newItem.price;
      }
      state.amount += newItem.price;
    },
    removeItemFromCart(state, action) {
      const { id, type, quantityToRemove = 1 } = action.payload;
      const existingItem = state.items.find(item => item.id === id && item.type === type);
      if (!existingItem) return;

      state.quantity -= quantityToRemove;
      if (existingItem.quantity <= quantityToRemove) {
        state.items = state.items.filter(item => item.id !== id || item.type !== type);
        state.amount -= existingItem.price * existingItem.quantity;
      } else {
        existingItem.quantity -= quantityToRemove;
        existingItem.totalPrice -= existingItem.price * quantityToRemove;
        state.amount -= existingItem.price * quantityToRemove;
      }
    },
    updateItemQuantity(state, action) {
      const { id, quantity } = action.payload;
      const existingItem = state.items.find(item => item.id === id);
      if (existingItem) {
        const quantityDifference = quantity - existingItem.quantity;
        existingItem.quantity = quantity;
        existingItem.totalPrice = existingItem.price * quantity;
        state.quantity += quantityDifference;
        state.amount += quantityDifference * existingItem.price;
      }
    },
    clearCart(state) {
      state.items = [];
      state.quantity = 0;
      state.amount = 0;
    }
  }
});

export const { addItemToCart, removeItemFromCart, updateItemQuantity, clearCart } = cartSlice.actions;

export default cartSlice.reducer;
