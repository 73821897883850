import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/ui/Button';
import { useNavigate } from 'react-router-dom';

const RequestSuccess = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div className="h-full pt-10 px-8 flex flex-col gap-16 justify-center">
      <div className="text-center text-white font-semibold flex flex-col gap-7 items-center">
        <span className="text-lg">
          {t('request.deposit.text')}
        </span>
        <span className="text-bae">
          {t('general.thanks')}
        </span>
        <img src="/icons/check-mark.svg" alt="lemon" width="50" />
      </div>
      <div className="flex justify-center items-center">
        <img src="/icons/large-locker-block.svg" alt="lemon" width="250" />
      </div>
      <div>
        <Button
          text={t('general.back-to-profile')}
          color="outline"
          onClick={() => navigate('/profile')}
        />
      </div>
    </div>
  )
}

export default RequestSuccess;