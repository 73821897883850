import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'helpers/api'
import { toast } from "react-toastify";
import { t } from 'i18next';

export const userAddress = createAsyncThunk('address/list', async (params, { dispatch }) => {
  try {
    const response = await api.get("screen02/user/address/list/" + params);
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

export const newAddress = createAsyncThunk('address/new', async (params, { dispatch }) => {

  const config = { headers: { "Content-Type": "multipart/form-data" } };

  try {
    const response = await api.post("screen02/user/address/new", params, config);
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

export const updateAddress = createAsyncThunk('address/update', async (params, { dispatch }) => {

  const config = { headers: { "Content-Type": "multipart/form-data" } };

  try {
    const response = await api.post("screen02/user/address/update", params, config);
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

export const defaultAddress = createAsyncThunk('address/default', async (params, { dispatch }) => {

  const config = { headers: { "Content-Type": "multipart/form-data" } };

  try {
    const response = await api.post("screen02/user/address/default", params, config);
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

export const deleteAddress = createAsyncThunk('address/delete', async (params, { dispatch }) => {

  try {
    const response = await api.delete("screen02/user/address/delete/" + params);
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

export const addressSlice = createSlice({
  name: 'address',
  initialState: {
    list: false,
    selected: false
  },

  reducers: {},
  extraReducers: builder => {
    builder.addCase(userAddress.fulfilled, (state, action) => {
      state.list = action.payload.Data
    })
  }
})

export default addressSlice.reducer
