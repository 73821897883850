import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useDispatch, useSelector } from 'react-redux';
import { updateSocketStatus } from 'store/app';
import cn from 'classnames';
import { updatePayment } from 'store/payment';
import { useNavigate } from 'react-router-dom';

export const WebSocket = ({ showIconStatus }) => {

  const dispatch = useDispatch();

  const { socket } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.auth);
  const { identifier } = useSelector((state) => state.payment);

  const navigate = useNavigate();

  const websocketUrl = process.env.REACT_APP_API_WS_URL + '/ws';

  const {
    readyState,
  } = useWebSocket(websocketUrl, {
    onOpen: () => changeSocketStatus(true, '::Socket Opened'),
    onClose: () => changeSocketStatus(false, '::Socket Closed'),
    onError: () => changeSocketStatus(false, '::Socket Error'),
    shouldReconnect: () => false,
    reconnectAttempts: 10,
    reconnectInterval: () => 60 * 1000, //60 seconds interval between each attemp
    onMessage: (e) => handleTrigger(e)
  })

  const connectionStatus = {
    [ReadyState.CONNECTING]: '::Connecting Socket',
    [ReadyState.OPEN]: '::Socket Opened',
    [ReadyState.CLOSING]: '::Closing Socket',
    [ReadyState.CLOSED]: '::Socket Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const changeSocketStatus = (active, status) => {
    dispatch(updateSocketStatus(active))
    // console.log(status)
  }

  const handleTrigger = (e) => {

    if (e.data && e.data.charAt(0) === '{') {
      const parsedMessage = JSON.parse(e.data);

      const { MsgType, Data } = parsedMessage;

      // console.log(parsedMessage)

      switch (MsgType) {
        case 'payment_success':
          if (Data.user === user.id && Data.identifier === identifier) {
            navigate('/payment/success/' +  Data.identifier)
            dispatch(updatePayment(true))
          }

          break;

        default:
          // console.log(connectionStatus)
      }
    }
  }

  return (
    showIconStatus &&
    <div
      className={cn(
        'rounded-full h-[10px] w-[10px] animate-pulse',
        {
          'bg-red-500': !socket.active,
          'bg-green-500': socket.active,
        }
      )}
    />
  )

};