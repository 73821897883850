import NavMenu from 'components/navigation/NavMenu';
import Button from 'components/ui/Button';
import HeaderCard from 'components/cards/HeaderCard';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const RequestTutorial = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div className="h-full pt-10 px-8 flex flex-col gap-5">
      <div>
        <NavMenu label={t('request.label')} to="/request/intro" text={t('header.request.text')} />
      </div>
      <div className='flex flex-col gap-5 overflow-y-auto h-full'>
        <div className=' h-fit'>
          <HeaderCard label={t('request.label') + ' #000000'} text={t('request.tutorial.title')} icon="/icons/laundry-machine.svg" />
        </div>
        <div className="flex flex-col gap-3 text-justify overflow-y-scroll">
          <span>
            {t('request.tutorial.text01')}
          </span>
          <span>
            {t('request.tutorial.text02')}
          </span>
          <span>
            {t('request.tutorial.text03')}
          </span>
          <span>
            {t('request.tutorial.text04')}
          </span>
        </div>
        <div className="py-5 mt-auto">
          <Button
            text={t('general.next')}
            onClick={() => navigate('/request/options')}
          />
        </div>
      </div>

    </div>
  )
}

export default RequestTutorial;