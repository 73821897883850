import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import NavMenu from 'components/navigation/NavMenu';
import HeaderCard from 'components/cards/HeaderCard';
import Subtitle from 'components/common/Subtitle';
import Button from 'components/ui/Button';

import { requestAssignLocker, requestDeposit } from 'store/request';
import { updateLoading } from 'store/app';

const RequestLocker = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { state } = useLocation();

  const { location, delivery, deposit, size, request } = state;

  const { id } = useSelector(state => state.purchase);

  const { selected: place } = useSelector(state => state.place);

  const [doorNumber, setDoorNumber] = useState(request.locker.doorNumber);

  const changeLocker = () => {
    dispatch(updateLoading(true))
    dispatch(requestAssignLocker({ request: request.id, size: size, location: location, locker: request.locker.id }))
      .then((res) => {
        if (res.payload.Result) {
          const data = res.payload.Data;
          setDoorNumber(data.locker.doorNumber);
        }
        dispatch(updateLoading(false))
      })
      .catch(() => {
        dispatch(updateLoading(false))
      })
  }

  const handleDeposit = () => {
    dispatch(updateLoading(true))
    dispatch(requestDeposit({ request: request.id }))
      .then((res) => {
        if (res.payload.Result) {
          navigate('/request/success')
        }
        dispatch(updateLoading(false))
      })
      .catch(() => {
        dispatch(updateLoading(false))
      })
  }

  useEffect(() => {
    if (!id || !location || !delivery || !deposit) {
      navigate('/purchases')
    }
  }, [id, location, delivery, deposit, navigate])

  return (
    <div className="h-full pt-10 pb-10 px-8 flex flex-col gap-6">
      <div>
        <NavMenu label={t('request.label')} text={t('header.request.text')} to="/request/qrcode" />
      </div>

      <div className="pt-5">
        <HeaderCard
          label={t('request.label') + ' #000000'}
          text={t('request.deposit.your-place', { place: place.name })}
          icon="/icons/map-pin.svg"
        />
      </div>

      <div className='flex-1 flex flex-col justify-between gap-6 overflow-y-auto'>
        <div className='flex w-full items-end'>
          <div className='w-[75%] pr-6'>
            <Subtitle title={t('request.locker.assigned')} text={t('request.locker.text01')} />
          </div>
          <div className='w-[25%]'>
            <Button text={t('request.locker.doorNumber', { count: doorNumber })} size='sm' />
          </div>
        </div>
        <div className="flex justify-center items-center">
          <img src="/icons/large-locker-block.svg" alt="lemon" />
        </div>
        <div className="flex flex-col gap-3">
          <Button
            text={t('request.deposit.label')}
            onClick={handleDeposit}
          />
          <div className="text-center">
            <span className="lowercase">
              {t('general.or')}
            </span>
          </div>
          <Button
            text={t('request.locker.replace')}
            color="outline"
            onClick={changeLocker}
          />
        </div>
      </div>
    </div>
  )
}

export default RequestLocker;