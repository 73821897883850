import React from 'react'
import { useTranslation } from 'react-i18next'

import Modal from 'components/ui/Modal'
import Button from "components/ui/Button";

const ConfirmDeleteAddress = ({ open, setOpen, submit }) => {
  const { t } = useTranslation();

  return (
    <Modal
      centered
      activeModal={open}
      onClose={() => setOpen(false)}
      title={
        <div className="flex gap-2 normal-case">
          <img src="/icons/map-pin-yellow.svg" alt="lemon" />
          <span>
            {t('address.sureDelete')}
          </span>
        </div>
      }
      themeClass="bg-white text-black"
      className="text-black"
    >
      <div className="grid grid-cols-2 gap-3">
        <Button
          color="outline"
          text="Cancelar"
          onClick={() => setOpen(false)}
        />
        <Button
          text="Apagar"
          onClick={submit}
        />
      </div>
    </Modal>
  )
}

export default ConfirmDeleteAddress