import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Card from 'components/ui/Card';
import Button from 'components/ui/Button';

import { activateAccount } from 'store/auth';
import Icon from 'components/ui/Icon';

const AccountActivated = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);

  const [state, setState] = useState('loading')
  const [error, setError] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(activateAccount({ token: id }))
        .then(res => {
          if (!res.payload.Result) {
            setState('error');
            setError(res.payload.Code);
          } else {
            setState('success');
          }
        })
        .catch(err => {
          setState('error');
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <div className='h-[50vh] px-4 flex justify-center items-center'>
        <Card
          layout
          transition={{ type: 'spring', duration: 0.4, bounce: 0 }}
          className="pb-4 pt-12 w-full max-w-md p-6 flex flex-col items-center justify-center space-y-6">
          {state === 'loading' && (
            <>
              <Icon icon='line-md:loading-alt-loop' className="h-8 w-8 animate-spin" />
              <p className="text-xl">A ativar a sua conta...</p>
            </>
          )}

          {state === 'success' && (
            <>
              <div className="text-3xl">✨</div>
              <h1 className="text-2xl font-semibold">{t('general.success')}!</h1>
              <p className="text-center text-muted-foreground">
                {t('auth.activate.success')}
              </p>
            </>
          )}

          {state === 'error' && (
            <>
              <div className="text-3xl">😥</div>
              <h1 className="text-2xl font-semibold">{t('auth.activate.error')}</h1>
              <div className="text-center text-muted-foreground">
                {(state === 'error' && error) &&
                  <p>{t(`api.errors.${error}`)}</p>
                }
              </div>
            </>
          )}

          <Button text={t('general.backToLogin')} onClick={() => navigate('/login')} />

        </Card>

      </div>
    </>
  )
}

export default AccountActivated;