import React from 'react';
import { useSelector } from 'react-redux';
import ReactLoading from 'react-loading'

const Loading = () => {
  const { loading } = useSelector((state) => state.app)

  return (
    loading &&
    <div className="flex flex-col gap-20 justify-center items-center bg-black fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto h-[100%] max-h-full">
      <img src="/lemon-logo.svg" alt='lemon' />
      <ReactLoading
        type="spinningBubbles"
        className="!fill-white"
        width="100px"
        height="100px"
        delay={50}
      />
    </div>
  )
}

export default Loading;