import React, { useState } from 'react';
import Card from 'components/ui/Card';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/pt';
import cn from 'classnames';
import Icon from 'components/ui/Icon';
import Button from 'components/ui/Button';
import { defaultAddress, deleteAddress } from 'store/address';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateLoading } from 'store/app';
import AddressModal from 'components/modals/AddressModal';
import ConfirmDeleteAddress from 'components/modals/ConfirmDeleteAddress';

moment.locale('pt');

const AddressCard = ({ address, reload }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);

  const { user } = useSelector(state => state.auth);

  const { street, zipcode, city, createdAt } = address;

  const [deleteModal, setDeleteModal] = useState(false);

  const onDefault = () => {
    if (!address.default) {
      dispatch(updateLoading(true));
      dispatch(defaultAddress({ address: address.id, user: user.id }))
        .then((res) => {
          if (res.payload.Result) {
            reload()
            toast.success(t('messages.success.address.updated'))
            dispatch(updateLoading(false));
          }
        })
    }
  }

  const onDelete = () => {
    dispatch(deleteAddress(address.id))
      .then((res) => {
        if (res.payload.Result) {
          reload()
          toast.success(t('messages.success.address.deleted'))
          setDeleteModal(false)
        }
      })
  }

  return (
    <Card>
      <AddressModal show={modal} onClose={() => setModal(false)} edit reload={reload} address={address} />
      <ConfirmDeleteAddress open={deleteModal} setOpen={setDeleteModal} address={address} submit={onDelete} />

      <div className="flex flex-col gap-2.5 py-2">
        <div className="flex items-center gap-3">
          <img src="/icons/map-pin-yellow.svg" alt="lemon" />
          <span className="font-semibold">
            {t('address.default')}
          </span>
          <div
            className={cn(
              'ml-auto h-5 w-5 border border-black rounded rounded-tr-none flex justify-center items-center',
              {
                'bg-black': address.default
              }
            )}
            onClick={onDefault}
          >
            {address.default && <Icon icon="lucide:check" className="text-white" width="15" />}
          </div>
        </div>
        <div>
          <span className="font-medium">
            {street}
          </span>
          <div className="flex gap-1">
            <span className="font-medium">
              {zipcode}
            </span>
            <span className="font-medium">
              {city}
            </span>
          </div>
        </div>
        <div>
          <span className="text-xs text-slate-400">
            {t('address.created-at')}{moment(createdAt, "DD-MM-YYYY HH:mm").format("D [de] MMMM [de] YYYY")}
          </span>
        </div>
        <div className="grid grid-cols-2 gap-5">
          <Button
            text={t('general.delete')}
            color="outline"
            size="sm"
            onClick={() => setDeleteModal(true)}
            // onClick={onDelete}
          />
          <Button
            text={t('general.edit')}
            size="sm"
            onClick={() => setModal(true)}
          />
        </div>
      </div>
    </Card>
  )
}

export default AddressCard;