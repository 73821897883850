import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from 'helpers/api'
import { toast } from "react-toastify";
import { t } from 'i18next';

export const getServiceCategories = createAsyncThunk('category/service/list', async (_, { dispatch }) => {
  try {
    const response = await api.get("api/category/list?type=service");
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

export const getProductCategories = createAsyncThunk('category/product/list', async (_, { dispatch }) => {
  try {
    const response = await api.get("api/category/list?type=pack");
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

const initialState = {
  services: false,
  products: false,
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getServiceCategories.fulfilled, (state, action) => {
      state.services = action.payload?.Data
    })
    builder.addCase(getProductCategories.fulfilled, (state, action) => {
      state.products = action.payload?.Data
    })
  }
});

export default categorySlice.reducer;