const sizeDescriptions = {
  S: {
    description: "Ideal para peças que não ocupam muito espaço, tais como:",
    items: [
      {
        icon: '/icons/baby-clothes.svg',
        title: 'Roupas de bebé ou criança:',
        description: 'Babygrows ou conjuntos pequenos'
      },
      {
        icon: '/icons/green-shirt.svg',
        title: 'Roupas de adulto:',
        description: 'T-shirts, tops, calções, peças pequenas'
      },
      {
        icon: '/icons/weight-kg.svg',
        title: 'Volume:',
        description: 'Cerca de 2 a 4 kg de roupa'
      }
    ]
  },
  M: {
    description: "Ideal para peças que não ocupam muito espaço, tais como:",
    items: [
      {
        icon: '/icons/baby-clothes.svg',
        title: 'Roupas de bebé ou criança:',
        description: 'Babygrows ou conjuntos pequenos'
      },
      {
        icon: '/icons/green-shirt.svg',
        title: 'Roupas de adulto:',
        description: 'T-shirts, tops, calções, peças pequenas'
      },
      {
        icon: '/icons/weight-kg.svg',
        title: 'Volume:',
        description: 'Cerca de 2 a 4 kg de roupa'
      }
    ]
  },
  L: {
    description: "Ideal para peças que não ocupam muito espaço, tais como:",
    items: [
      {
        icon: '/icons/baby-clothes.svg',
        title: 'Roupas de bebé ou criança:',
        description: 'Babygrows ou conjuntos pequenos'
      },
      {
        icon: '/icons/green-shirt.svg',
        title: 'Roupas de adulto:',
        description: 'T-shirts, tops, calções, peças pequenas'
      },
      {
        icon: '/icons/weight-kg.svg',
        title: 'Volume:',
        description: 'Cerca de 2 a 4 kg de roupa'
      }
    ]
  },
};


export default sizeDescriptions;