const stateColors = {
  1: "bg-[#DDA0DD]", // Using Tailwind class syntax
  2: "bg-[#87CEFA]",
  3: "bg-[#FFA07A]",
  4: "bg-[#FFD700]",
  5: "bg-[#FF6347]",
  6: "bg-[#40E0D0]",
  7: "bg-[#32CD32]",
  8: "bg-[#FF8C00]",
  9: "bg-[#4682B4]",
  10: "bg-[#BD3A09]",
  11: "bg-[#BD3A09]",
  12: "bg-[#57BE92]",
  13: "bg-[#B22222]",
  14: "bg-[#00CED1]",
  15: "bg-[#5F9EA0]",
  16: "bg-[#8B0000]"
};

export default stateColors;