import React, { useEffect, useState } from 'react';
import { appSettings } from 'config/app';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Outlet, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import Loading from 'components/modals/Loading';
import Header from 'components/navigation/Header';
import Footer from 'components/navigation/Footer';
import { WebSocket } from 'helpers/socket';

const Layout = () => {

  const location = useLocation();

  const { pathname } = location;

  const hideHeader = ['/intro', '/regist', '/login', '/cart', '/payment', '/purchases', '/requests', '/request/intro', '/request/tutorial', '/request/options', '/request/qrcode', '/request/size', '/request/locker', '/request/success', '/request/success/counter', '/account', '/history'];
  const hideFooter = ['/intro', '/regist', '/login', '/cart', '/request/options', '/request/qrcode', '/request/size', '/request/locker', '/request/success', '/request/success/counter', '/account', '/payment'];
  const hideShape = ['/intro', '/regist', '/login', '/cart', '/payment', '/request/intro', '/request/success', '/request/success/counter'];
  const fullBody = ['/intro', '/regist', '/login', '/cart', '/request/options', '/request/qrcode', '/request/size', '/request/locker', '/request/success', '/request/success/counter', '/account', '/payment',];

  return (
    <div className='max-w-lg bg-zinc-400 w-full m-auto'>
      <div className="flex flex-col w-full bg-black h-dvh relative text-sm font-light overflow-hidden">
        <Loading />
        <ToastContainer
          position={appSettings.toast.position}
        />
        <WebSocket />
        <div
          className={cn(
            'w-full px-8 z-20 relative',
            {
              'h-[22dvh] flex': !hideHeader.includes(pathname),
              'hidden': hideHeader.includes(pathname) || pathname.includes('/payment') || pathname.includes('/request/details'),
            }
          )}
        >
          <Header />
        </div>
        <div
          className={cn(
            'flex z-20 relative',
            {
              'h-[100dvh]': fullBody.includes(pathname) || pathname.includes('/payment/success') || pathname.startsWith('/activate-account'),
              'h-[63dvh]': !hideHeader.includes(pathname) && !hideFooter.includes(pathname) && !pathname.includes('/payment/success'),
              // eslint-disable-next-line no-mixed-operators
              'h-[85dvh]': hideHeader.includes(pathname) && !hideFooter.includes(pathname) || pathname.includes('/request/details')
            }
          )}
        >
          {(!hideShape.includes(pathname) && !pathname.includes('/payment/success')) && !pathname.includes('/activate-account') &&
            <div
              className={cn(
                'absolute z-10 w-full pointer-events-none',
                {
                  '-bottom-[15dvh]': !fullBody.includes(pathname),
                  'bottom-0': fullBody.includes(pathname)
                }
              )}
            >
              <WaveSVG />
            </div>
          }

          <AnimatePresence mode="popLayout" initial={false}>
            <AnimatedOutlet />
          </AnimatePresence>

           {/* <motion.div
              key={path}
              initial={{ opacity: 0, x: '100%' }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: '-100%' }}
              transition={{ duration: 0.6, type: 'spring', bounce: 0 }}
              className="z-20 w-full overflow-y-auto"
            >
              <Outlet />
            </motion.div> */}

        </div>

        {(!hideFooter.includes(pathname) && !pathname.startsWith("/payment/success")) && !pathname.includes('/activate-account') &&
          <div className={cn('w-full z-20 relative h-[15dvh] flex', {
            "bg-white": (pathname !== '/payment' && !pathname.includes('/payment/success'))
          })}>
            <Footer />
          </div>
        }
        {hideFooter.includes(pathname) && <div className='w-full z-20 relative hidden'><Footer /></div>}

        <img src="/images/background.svg" height="100%" width="100%" className="absolute top-0 left-0 z-10" alt="lemon" />
      </div>
    </div>
  )
}

function AnimatedOutlet() {
  const location = useLocation();
  
  return (
    <motion.div
      key={location.pathname}
      initial={{ opacity: 0, x: '100%' }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: '-100%' }}
      transition={{ duration: 0.6, type: 'spring', bounce: 0 }}
      className="z-20 w-full overflow-y-auto"
    >
      <Outlet />
    </motion.div>
  );
}

const WaveSVG = () => {
  const [height, setHeight] = useState(400);

  // need to find a consensus here, in terms of values.
  // some pages, just don't look good. unless i also define max height. 
  useEffect(() => {
    const handleResize = () => {
      const deviceHeight = window.innerHeight;
      const thirtyVh = (deviceHeight * 22.5) / 110;
      const newHeight = deviceHeight - thirtyVh;
      setHeight(Math.min(Math.max(newHeight, 500), 1200));
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <svg
      width="100%"
      height={`${height}px`}
      viewBox={`0 0 390 ${height}`}
      fill="none"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={`M0 28C0 28 73.5 90 195 28C316.5 -34 390 28 390 28V${height}H0V28Z`}
        fill="white"
      />
    </svg>
  );
};


export default Layout;