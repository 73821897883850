import React, { useState, useEffect } from 'react';
import NavMenu from 'components/navigation/NavMenu';
import { useTranslation } from 'react-i18next';
import Card from 'components/ui/Card';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/ui/Button';
import Input from 'components/ui/Input';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from 'react-toastify';
import Divider from 'components/ui/Divider';
import { updateLoading } from 'store/app';
import { userAddress } from 'store/address';
import AddressCard from 'components/cards/AddressCard';
import AddressModal from 'components/modals/AddressModal';
import { userUpdate } from 'store/user';
import { useNavigate } from 'react-router-dom';
import { updateLogged } from 'store/auth';

const Account = () => {
  const { t } = useTranslation();

  const [edit, setEdit] = useState(false);
  const [modal, setModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector(state => state.auth);
  const { list } = useSelector(state => state.address);

  const schema = yup
    .object({
      name: yup.string().required(t('form.required.label')),
      phone: yup.string().required(t('form.required.label')),
      email: yup.string().required(t('form.required.label')),
    })
    .required();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      nif: user.nif,
      name: user.name,
      phone: user.phone,
      email: user.email,
    },
  });

  const onSubmit = (values) => {
    dispatch(updateLoading(true))
    dispatch(userUpdate({ id: user.id, ...values }))
      .then((res) => {
        if (res.payload.Result === 1) {
          navigate('/profile')
          dispatch(updateLogged(res.payload.Data));
          toast.success(t('messages.success.user.updated'))
          reset()
        }
        dispatch(updateLoading(false))
      })
      .catch(() => {
        dispatch(updateLoading(false))
      })
  };

  const cancel = () => {
    reset();
    setEdit(false);
  }

  const getAddress = () => {
    dispatch(updateLoading(true))
    dispatch(userAddress(user.id))
      .then((res) => {
        if (res.payload.Result) {
          dispatch(updateLoading(false))
        }
      })
      .catch(() => {
        dispatch(updateLoading(false))
      })
  }

  useEffect(() => {
    getAddress()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user) {
      setValue('id', user.id);
      setValue('name', user.name);
      setValue('phone', user.phone);
      setValue('prefix', user.numberPrefix);
      setValue('nif', user.nif ? user.nif : '');
      setValue('email', user.email);
      setValue('avatar', user.avatar);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <div className="h-full pt-10 flex flex-col gap-5">
      <AddressModal show={modal} onClose={() => setModal(false)} reload={getAddress} />
      <div className="px-8">
        <NavMenu to="/profile" label={t('account.label')} text={t('account.text01')} />
      </div>
      <div className="flex flex-col justify-center items-center gap-3 px-8">
        <div className="bg-teal-100 h-28 w-28 rounded-full flex justify-center items-center drop-shadow-lg">

        </div>
        <span className="font-semibold text-lg">
          {t('account.personal-data')}
        </span>
      </div>
      <div className="flex flex-col gap-5 px-8 pb-5 overflow-y-scroll">
        <Card>
          <Input
            name="name"
            type="text"
            placeholder={t('user.full-name')}
            label={t('user.full-name')}
            register={register}
            error={errors.text}
            noMsgError
            disabled={!edit}
          />
        </Card>
        <Card>
          <Input
            name="nif"
            type="number"
            placeholder={t('user.nif')}
            label={t('user.nif')}
            register={register}
            error={errors.nif}
            noMsgError
            disabled={!edit}
          />
        </Card>
        <Card>
          <Input
            name="phone"
            type="number"
            placeholder={t('user.phone')}
            label={t('user.phone')}
            register={register}
            error={errors.phone}
            noMsgError
            disabled={!edit}
          />
        </Card>
        <Card>
          <Input
            name="email"
            type="text"
            placeholder={t('user.email')}
            label={t('user.email')}
            register={register}
            error={errors.email}
            noMsgError
            disabled={!edit}
          />
        </Card>
        <Card>
          <Input
            name="password"
            type="password"
            placeholder={t('user.password')}
            label={t('user.password')}
            register={register}
            error={errors.password}
            noMsgError
            disabled={!edit}
            hasicon
          />
        </Card>
        <div className="grid grid-cols-2 gap-5">
          {!edit
            ?
            <Button
              text={t('general.edit')}
              color="outline"
              onClick={() => setEdit(true)}
            />
            :
            <Button
              text={t('general.cancel')}
              color="outline"
              onClick={cancel}
            />
          }
          <Button
            text={t('general.save')}
            isDisabled={!edit}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
        <Divider text={t('address.label', { count: 2 })} />
        <div className="flex flex-col gap-5">
          {list && list.map((item, index) =>
            <div key={index}>
              <AddressCard address={item} reload={getAddress} />
            </div>
          )}
          <Button
            text={t('address.add')}
            onClick={() => setModal(true)}
          />
        </div>
      </div>
    </div>
  )
}

export default Account;