import React from 'react';

const Subtitle = ({ title, text }) => {
  return (
    <div className="flex flex-col gap-1">
      <span className="font-semibold text-lg">
        {title}
      </span>
      <span className="text-zinc-400 text-sm">
        {text}
      </span>
    </div>
  )
}

export default Subtitle