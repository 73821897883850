import React from "react";
import CheckImage from "assets/ck-white.svg";
const Checkbox = ({
  id,
  disabled,
  label,
  value,
  name,
  onChange,
  activeClass = "ring-black  bg-black",
  align = 'right'
}) => {
  return (
    <label
      className={`flex items-center gap-x-5 ${disabled ? " cursor-not-allowed opacity-50" : "cursor-pointer"
        }`}
      id={id}
    >
      {align === 'left' &&
        <span className="text-slate-500 text-sm leading-6 capitalize">
          {label}
        </span>
      }
      <input
        type="checkbox"
        className="hidden"
        name={name}
        checked={value}
        onChange={onChange}
        htmlFor={id}
        disabled={disabled}
      />
      <span
        className={`h-4 w-4 border flex-none border-black bg-black rounded 
        inline-flex ltr:mr-3 rtl:ml-3 relative transition-all duration-150
        ${value
            ? activeClass + " ring-2 ring-offset-2 ring-black"
            : "bg-slate-100"
          }
        `}
      >
        {value && (
          <img
            src={CheckImage}
            alt=""
            className="h-[10px] w-[10px] block m-auto text-white"
          />
        )}
      </span>
      {align === 'right' &&
        <span className="text-slate-500 text-sm leading-6 capitalize">
          {label}
        </span>
      }
    </label>
  );
};

export default Checkbox;
