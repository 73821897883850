import NavMenu from 'components/navigation/NavMenu';
import Button from 'components/ui/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const RequestIntro = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div className="h-full pt-10 flex flex-col gap-5">
      <div className="px-8">
        <NavMenu label={t('request.label')} to="/purchases" />
      </div>
      <div className="text-white/90 text-justify flex flex-col gap-4 px-8">
        <span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et fermentum lorem. Sed convallis ligula tellus, et semper dolor ullamcorper vel. Pellentesque commodo condimentum.
        </span>
        <span>
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </span>
        <span>
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        </span>
        <span>
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </span>
      </div>
      <div className="bg-white mt-auto pt-14 pb-7 rounded-tl-[65px] px-8">
        <Button
          text={t('general.next')}
          onClick={() => navigate('/request/tutorial')}
        />
      </div>
    </div>
  )
}

export default RequestIntro;