import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProductCategories } from 'store/category';
import CategoryCard from 'components/cards/CategoryCard';
import { useLocation } from 'react-router-dom';
import Card from 'components/ui/Card';

const Products = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const { products: categories } = useSelector(state => state.category);

  useEffect(() => {
    if (!categories) dispatch(getProductCategories());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="px-8 w-full">
      <div className="flex flex-col gap-5 w-full pb-4">
        {!categories ?
          <CategoriesSkeleton />
          : categories.map((item, index) =>
            <div key={index}>
              <CategoryCard
                open={state ? (state.expanded === item.id) : false}
                category={item}
                type="product"
              />
            </div>
          )}
      </div>
    </div>
  )
}

const CategoriesSkeleton = () => {
  return (
    Array.from({ length: 4 }).map((_, index) => (
      <Card key={index}>
        <div className="py-1.5 flex items-center gap-5 cursor-pointer">
          <div className="w-[15%]">
            {/* Image placeholder - circle */}
            <div className="w-12 h-12 bg-slate-100 rounded-full"></div>
          </div>
          <div className="flex justify-start w-[75%] overflow-hidden">
            <div className="bg-slate-100 w-3/4 h-4 rounded-md"></div> {/* Text placeholder */}
          </div>
          <div className="flex justify-end">
            <div className="bg-slate-100 w-8 h-8 rounded-md"></div> {/* Chevron/arrow placeholder */}
          </div>
        </div>
      </Card>
    ))
  );
};

export default Products;