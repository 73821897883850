import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Card from 'components/ui/Card';
import HistoryCard from 'components/cards/HistoryCard';
import NavMenu from 'components/navigation/NavMenu';

import { getRequestsHistory } from 'store/request';

const HistoryPage = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { history } = useSelector(state => state.request);
  const { user } = useSelector(state => state.auth);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      return navigate('/login');
    }

    dispatch(getRequestsHistory(user.id))
      .then(() => {
        setLoading(false);
      })

  }, [user, dispatch, navigate])

  return (
    <div className="h-full pt-10 pb-10 px-8 flex flex-col gap-6">
      <div>
        <NavMenu label={t('history.label')}
          text={t('history.text')}
          to="/profile" />
      </div>
      <div className='overflow-y-auto mt-14 space-y-4 pb-1 px-1'>
        {loading
          ? <Skeleton />
          : history && history.map((history, index) => (
            <HistoryCard history={history} key={index} />
          ))}
      </div>
    </div>
  )
}

const Skeleton = () => {

  return (
    Array.from({ length: 2 }).map((_, index) => (
      <Card className='py-4 px-5 shadow-lg' key={index}>
        <div className='flex flex-col'>
          <div className='h-4 bg-slate-100 rounded-sm w-3/4' />
          <div className='h-4 bg-slate-100 rounded-sm w-2/3' />
        </div>
        <div className='mt-1'>
          <div className='flex items-end  justify-between'>
            <div className='flex flex-col gap-x-0.5 text-zinc-400'>
              <div className='h-4 bg-slate-100 rounded-sm w-1/3' />
              <div className='h-4 bg-slate-100 rounded-sm w-2/5' />
            </div>
            <div className='h-4 bg-slate-100 rounded-sm w-12' />
          </div>
        </div>
      </Card>
    ))

  )
}

export default HistoryPage;