import React from "react"
import { Dialog } from "@headlessui/react";
import { motion, AnimatePresence } from 'framer-motion';
import { QrReader } from 'react-qr-reader';
import { useDispatch } from 'react-redux';

import { getSizes, placeDetails } from 'store/place';
import Icon from "components/ui/Icon";

const QRModal = ({ show, onClose, setLocation, setOffline, setFull }) => {

  const dispatch = useDispatch();

  const onResult = (result) => {
    if (result?.text) {

      const url = new URL(result.text);
      const location = url.searchParams.get('location');

      dispatch(placeDetails(parseInt(location)))
        .then((res) => {
          if (res.payload.Result) {
            if (res.payload.Data.offline) {
              setOffline(true);
            } else if (parseInt(res.payload.Data.lockers.occupied) >= parseInt(res.payload.Data.lockers.total)) {
              setFull(true);
            } else {
              dispatch(getSizes(parseInt(location)))
            }
            onClose()
            setLocation(parseInt(location));
          }
        })
    }
  }

  return (
    <AnimatePresence>
      {show && (
        <Dialog
          open={show}
          onClose={onClose}
          as="div"
          className="fixed inset-0 z-[99999] flex items-center justify-center"
        >
          <div className="absolute inset-0 bg-black opacity-90" />
          <div onClick={onClose}>
            <Icon icon="heroicons:x-mark" className="text-white absolute top-20 right-5" width="30" />
          </div>
          <div className="flex flex-col w-full">
            <motion.div
              className="flex flex-col items-center justify-center min-h-screen min-w-screen"
              initial={{
                opacity: 0,
                scale: 0.75,
              }}
              animate={{
                opacity: 1,
                scale: 1,
                transition: {
                  ease: "easeOut",
                  duration: 0.15,
                },
              }}
              exit={{
                opacity: 0,
                scale: 0.75,
                transition: {
                  ease: "easeIn",
                  duration: 0.15,
                },
              }}
            >

              <div className="w-3/4">
                <QrReader
                  constraints={{ facingMode: 'environment' }}
                  onResult={(result) => {
                    if (!!result) {
                      onResult(result);
                    }
                  }}
                  style={{ width: '100%' }}
                />
              </div>
            </motion.div>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  )
}

export default QRModal;