export const menu = [
  {
    label: 'home',
    icon: 'lemon',
    url: '/home',
  },
  {
    label: 'services',
    icon: 'iron',
    url: '/services',
  },
  {
    label: 'products',
    icon: 'machine',
    url: '/products',
  },
  {
    label: 'profile',
    icon: 'user',
    url: '/profile',
  }
];