import React from 'react';
import cn from 'classnames';
import { motion } from 'framer-motion';

const Card = ({ children, title, className, pulse, ...props }) => {
  return (
    <motion.div
      className={cn(
        'bg-white w-full rounded-lg rounded-tr-none shadow-lg flex flex-col py-2 px-3',
        className, {
        "animate-pulse bg-gray-50 duration-300": pulse
      }
      )}
      {...props}
    >
      {title &&
        <div>
          <span className="text-xl font-normal">
            {title}
          </span>
        </div>
      }
      {children}
    </motion.div>
  )
}

export default Card;