import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from 'helpers/api'
import { toast } from "react-toastify";
import { t } from 'i18next';

export const getServices = createAsyncThunk('service/list', async (_, { dispatch }) => {
  try {
    const response = await api.get("api/service/list");
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

const initialState = {
  list: false
};

export const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getServices.fulfilled, (state, action) => {
      state.list = action.payload?.Data
    })
  }
});

export default serviceSlice.reducer;
