import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, useMotionValue, useAnimationControls } from 'framer-motion';
import Card from 'components/ui/Card';


const HorizontalCarousel = ({ items, itemType }) => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef(null);
  const x = useMotionValue(0);
  const controls = useAnimationControls();

  const ITEM_WIDTH = 64;
  const EXPANDED_WIDTH = 256;
  const GAP = 12;

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    if (!containerRef.current) return;

    const currentX = x.get();
    const itemPosition = -currentX / (ITEM_WIDTH + GAP);
    const newIndex = Math.round(Math.max(0, Math.min(itemPosition, items.length - 1)));
    const snapToPosition = -(newIndex * (ITEM_WIDTH + GAP));

    controls.start({
      x: snapToPosition,
      transition: { type: "spring", stiffness: 300, damping: 30, duration: 1 }
    });

    setActiveIndex(newIndex);
    setIsDragging(false);
  };

  const handleCardClick = (item) => {
    if (!isDragging) {
      navigate(`/${itemType}s`, { state: { expanded: item.id } });
    }
  };

  return (
    <div className="relative w-full overflow-hidden" ref={containerRef}>
      <motion.div
        className="flex py-3 gap-3 items-stretch cursor-grab active:cursor-grabbing"
        drag="x"
        dragConstraints={{
          right: 0,
          left: -((items.length - 1) * (ITEM_WIDTH + GAP))
        }}
        dragElastic={0.1}
        dragMomentum={false}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        animate={controls}
        style={{ x }}
      >
        {items ? items.map((item, index) => (
          <motion.div
            id={`${itemType}-${item.id}`}
            key={index}
            layout
            className="flex-shrink-0"
            animate={{
              width: index === activeIndex ? EXPANDED_WIDTH : ITEM_WIDTH,
            }}
            transition={{
              type: "spring",
              stiffness: 500,
              duration: 1,
              damping: 30
            }}
            onClick={() => handleCardClick(item)}
          >
            <motion.div layout="position" className="h-full">
              <Card className="h-full shadow-md">
                <motion.div
                  layout="position"
                  className="h-full"
                >
                  <motion.div
                    layout="position"
                    className={`flex ${index === activeIndex ? 'gap-3 items-center' : 'justify-center'}`}
                  >
                    <motion.img
                      layout="position"
                      src={`/icons/${itemType}/${item.icon}.svg`}
                      alt={item.name}
                      transition={{ duration: 0.5 }}
                      className="object-contain p-2 w-11 h-11 select-none pointer-events-none"
                    />
                    {index === activeIndex && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="overflow-hidden"
                      >
                        <h3 className="font-medium text-xs text-gray-900 text-nowrap truncate">
                          {item.name}
                        </h3>
                      </motion.div>
                    )}
                  </motion.div>
                </motion.div>
              </Card>
            </motion.div>
          </motion.div>
        ))
          :
          <motion.div
            layout
            className="flex-shrink-0"
            transition={{
              type: "spring",
              stiffness: 500,
              duration: 1,
              damping: 30
            }}
          >
            <motion.div layout="position" className="h-full">
              <Card className="h-full shadow-sm">
                <motion.div
                  layout="position"
                  className="h-full"
                >
                  <p>Loading...</p>
                </motion.div>
              </Card>
            </motion.div>
          </motion.div>
        }
      </motion.div>
    </div>
  );
};

export const CarouselSkeleton = () => {
  return (
    <div className="relative w-full overflow-hidden">
      <div className="flex py-1.5 gap-3 items-stretch cursor-default">
        <div className="flex-shrink-0 w-64 rounded shadow-sm p-2">
          <div className="flex gap-3 items-center">
            <div className="w-11 h-11 bg-slate-100 rounded-full animate-pulse" />
            <div className="w-24 h-4 bg-slate-100 rounded mt-2 animate-pulse" />
          </div>
        </div>
        <div className="flex-shrink-0 w-16 rounded shadow-sm p-2 flex justify-center items-center">
          <div className="w-11 h-11 bg-slate-100 rounded-full animate-pulse" />
        </div>
        <div className="flex-shrink-0 w-16 rounded shadow-sm p-2 flex justify-center items-center">
          <div className="w-11 h-11 bg-slate-100 rounded-full animate-pulse" />
        </div>
      </div>
    </div>
  )
}

export default HorizontalCarousel;