import React from 'react';
import Icon from 'components/ui/Icon';
import { useNavigate } from 'react-router-dom';
import Cart from 'components/common/Cart';

const NavMenu = ({ to, label, text }) => {

  const navigate = useNavigate();

  return (
    <div className="h-[15%] flex flex-col gap-8">
      <div className="grid grid-cols-3 [&>*]:flex items-center">
        <div className="justify-start" onClick={() => navigate(to)}>
          <Icon icon="heroicons:arrow-long-left" width="30" className="text-white" />
        </div>
        <div className="justify-center">
          <span className="text-white text-2xl">
            {label}
          </span>
        </div>
        <div className="justify-end">
          <Cart />
        </div>
      </div>
      {text &&
        <div>
          <span className="text-white/80 text-xs">
            {text}
          </span>
        </div>
      }
    </div>
  )
}

export default NavMenu;