import React, { Suspense } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import Layout from "layout";
import { AxiosInterceptor } from 'helpers/api';
import AuthGuard from "components/security/AuthGuard";
import RequestSuccessCounter from "views/request/success-counter";
import RequestDetailsPage from "views/request/details";
import Intro from "views/intro";
import Homepage from "views/home";
import Regist from "views/regist";
import Login from "views/login";
import Services from "views/service";
import Products from "views/product";
import CartPage from "views/cart";
import PaymentPage from "views/payment";
import Profile from "views/profile";
import Account from "views/account";
import PurchasePage from "views/purchase";
import RequestPage from "views/request";
import HistoryPage from "views/history";
import RequestIntro from "views/request/intro";
import RequestTutorial from "views/request/tutorial";
import RequestOptions from "views/request/options";
import RequestQRCode from "views/request/qrcode";
import RequestSize from "views/request/size";
import RequestLocker from "views/request/locker";
import PaymentSuccessPage from "views/payment/success";
import RequestSuccess from "views/request/success";
import AccountActivated from "views/account-activated";

// const Intro = React.lazy(() => import(/* webpackChunkName: "views-intro" */ "views/intro"));
// const Homepage = React.lazy(() => import(/* webpackChunkName: "views-home" */ "views/home"));
// const Regist = React.lazy(() => import(/* webpackChunkName: "views-regist" */ "views/regist"));
// const Login = React.lazy(() => import(/* webpackChunkName: "views-login" */ "views/login"));
// const Produts = React.lazy(() => import(/* webpackChunkName: "views-product" */ "views/product"));
// const Services = React.lazy(() => import(/* webpackChunkName: "views-service" */ "views/service"));
// const Profile = React.lazy(() => import(/* webpackChunkName: "views-profile" */ "views/profile"));
// const CartPage = React.lazy(() => import(/* webpackChunkName: "views-cart" */ "views/cart"));
// const PaymentPage = React.lazy(() => import(/* webpackChunkName: "views-payment" */ "views/payment"));
// const PaymentSuccess = React.lazy(() => import(/* webpackChunkName: "views-success" */ "views/payment/success"));
// const Account = React.lazy(() => import(/* webpackChunkName: "views-account" */ "views/account"));
// const PurchasePage = React.lazy(() => import(/* webpackChunkName: "views-purchase" */ "views/purchase"));
// const RequestPage = React.lazy(() => import(/* webpackChunkName: "views-request" */ "views/request"));
// const RequestIntro = React.lazy(() => import(/* webpackChunkName: "views-request-intro" */ "views/request/intro"));
// const RequestTutorial = React.lazy(() => import(/* webpackChunkName: "views-request-tutorial" */ "views/request/tutorial"));
// const RequestOptions = React.lazy(() => import(/* webpackChunkName: "views-request-options" */ "views/request/options"));
// const RequestQRCode = React.lazy(() => import(/* webpackChunkName: "views-request-qrcode" */ "views/request/qrcode"));
// const RequestSize = React.lazy(() => import(/* webpackChunkName: "views-request-size" */ "views/request/size"));
// const RequestLocker = React.lazy(() => import(/* webpackChunkName: "views-request-locker" */ "views/request/locker"));
// const RequestSuccess = React.lazy(() => import(/* webpackChunkName: "views-request-success" */ "views/request/success"));
// const HistoryPage = React.lazy(() => import(/* webpackChunkName: "views-history" */ "views/history"));
const ForbiddenPage = React.lazy(() => import(/* webpackChunkName: "views-403" */ "views/403"));
const NotFoundPage = React.lazy(() => import(/* webpackChunkName: "views-404" */ "views/404"));

const Router = () => {
  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <AxiosInterceptor>
          {/* <Layout> */}
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Navigate to="/intro" />} />
                <Route exact path="/intro" element={<Intro />} />
                <Route exact path="/home" element={<Homepage />} />
                <Route exact path="/regist" element={<Regist />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/services" element={<Services />} />
                <Route exact path="/products" element={<Products />} />
                <Route exact path="/cart" element={<CartPage />} />
                <Route exact path="/payment" element={<PaymentPage />} />
                {/* Protected Routes */}
                <Route element={<AuthGuard />}>
                  <Route exact path="/profile" element={<Profile />} />
                  <Route exact path="/payment/success/:identifier" element={<PaymentSuccessPage />} />
                  <Route exact path="/account" element={<Account />} />
                  <Route exact path="/purchases" element={<PurchasePage />} />
                  <Route exact path="/requests" element={<RequestPage />} />
                  <Route exact path="/history" element={<HistoryPage />} />
                  <Route exact path="/request/intro" element={<RequestIntro />} />
                  <Route exact path="/request/tutorial" element={<RequestTutorial />} />
                  <Route exact path="/request/options" element={<RequestOptions />} />
                  <Route exact path="/request/qrcode" element={<RequestQRCode />} />
                  <Route exact path="/request/size" element={<RequestSize />} />
                  <Route exact path="/request/locker" element={<RequestLocker />} />
                  <Route exact path="/request/success" element={<RequestSuccess />} />
                  <Route exact path="/request/success/counter" element={<RequestSuccessCounter />} />
                  <Route exact path="/request/details/:id" element={<RequestDetailsPage />} />

                </Route>

                <Route exact path="/activate-account/:id" element={<AccountActivated />} />


                <Route exact path="/403" element={<ForbiddenPage />} />
                <Route path="*" element={<NotFoundPage />} />
              </Route>
            </Routes>
          {/* </Layout> */}
        </AxiosInterceptor>
      </BrowserRouter>
    </Suspense>
  );
};

export default Router;
