import React from 'react';
import Input from 'components/ui/Input';
import Button from 'components/ui/Button';
import Card from 'components/ui/Card';
import Icon from 'components/ui/Icon';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { regist } from 'store/auth';
import { updateLoading } from 'store/app';

const Regist = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const schema = yup
    .object({
      email: yup.string().required(t('form.required.label')),
      password: yup.string().required(t('form.required.label')),
      name: yup.string().required(t('form.required.label')),
      phone: yup.string().required(t('form.required.label')),
    })
    .required();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = (values) => {
    dispatch(updateLoading(true))
    dispatch(regist(values))
      .then((res) => {
        if (res.payload.Result === 1) {
          toast.success(t('messages.success.user.created'))
          navigate('/login')
        }
        reset()
        dispatch(updateLoading(false))
      })
      .catch(() => {
        reset()
        dispatch(updateLoading(false))
      })
  };

  return (
    <div className="w-full h-full pt-10 flex flex-col gap-20">
      <div className="grid grid-cols-3 [&>*]:flex items-center px-8">
        <div className="justify-start" onClick={() => navigate('/intro')}>
          <Icon icon="heroicons:arrow-long-left" width="30" className="text-white" />
        </div>
        <div className="justify-center">
          <span className="text-white text-2xl">
            {t('regist.label')}
          </span>
        </div>
      </div>
      <div className="bg-white flex-1 rounded-tl-[75px] flex flex-col pt-10 px-8 gap-5">
        <Card>
          <Input
            name="name"
            type="name"
            placeholder={t('user.full-name')}
            label={t('user.full-name')}
            register={register}
            error={errors.name}
            noMsgError
          />
        </Card>
        <Card>
          <Input
            name="nif"
            type="number"
            placeholder={t('user.nif')}
            label={t('user.nif')}
            register={register}
            error={errors.nif}
            noMsgError
          />
        </Card>
        <Card>
          <Input
            name="phone"
            type="number"
            placeholder={t('user.phone')}
            label={t('user.phone')}
            register={register}
            error={errors.phone}
            noMsgError
          />
        </Card>
        <Card>
          <Input
            name="email"
            type="text"
            placeholder={t('user.email')}
            label={t('user.email')}
            register={register}
            error={errors.email}
            noMsgError
          />
        </Card>
        <Card>
          <Input
            name="password"
            type="password"
            placeholder={t('user.password')}
            label={t('user.password')}
            register={register}
            error={errors.password}
            noMsgError
            hasicon
          />
        </Card>
        <Button
          onClick={handleSubmit(onSubmit)}
          text={t('auth.regist')}
        />
        <div className="flex justify-center items-center gap-x-2 pt-4">
          <span>
            {t('auth.already-have-account')}
          </span>
          <span className="text-black font-normal cursor-pointer underline" onClick={() => navigate('/login')}>
            {t('auth.make-login')}
          </span>
        </div>
      </div>
    </div>
  )
}

export default Regist;