/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import cn from 'classnames';

import NavMenu from 'components/navigation/NavMenu';
import Badge from 'components/ui/Badge';
import Card from 'components/ui/Card';

import stateColors from 'config/stateColors';
import Button from 'components/ui/Button';
import { updateLoading } from 'store/app';
import Subtitle from 'components/common/Subtitle';

import { cancelRequest, requestDeposit, requestDetails } from 'store/request';

import moment from 'moment';
import 'moment/locale/pt'
moment.locale('pt');

const RequestDetailsPage = () => {

  const { id } = useParams();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { selected } = useSelector(state => state.request);

  const fetch = () => {
    setLoading(true);
    dispatch(requestDetails(id))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }

  const openLocker = () => {
    dispatch(updateLoading(true));

    dispatch(requestDeposit({ request: selected.id }))
      .then((res) => {
        if (res.payload.Result) {
          toast.success(t('messages.success.locker.open'));
          navigate('/request/success')
        }
        dispatch(updateLoading(false))
      })
      .catch(() => {
        dispatch(updateLoading(false))
      })
  }

  const handleCancelRequest = () => {
    dispatch(updateLoading(true));

    dispatch(cancelRequest({ request: selected.id }))
      .then((res) => {
        if (res.payload.Result) {
          toast.success(t('messages.success.request.canceled'));
          navigate('/home')
        }
        dispatch(updateLoading(false))
      })
      .catch(() => {
        dispatch(updateLoading(false))
      })
  }

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    selected &&
    <div className="h-full pt-10 pb-10 px-6 flex flex-col gap-6">
      <NavMenu label={t('request.label')} text={t('header.request.text')} to="/profile" />
      {loading ?
        <PageSkeleton />
        :
        <div className='flex flex-col pb-4 overflow-y-auto'>
          <Card className='flex flex-col gap-4 rounded-lg mt-6 space-y-4'>
            <div className='p-4'>
              <Subtitle title={t('request.details')} text={t('request.detailsText')} />
              <div className='flex justify-between pt-4'>
                <div className='flex flex-col gap-x-0.5'>
                  <p className='font-semibold'>
                    {selected.purchase.product ? selected.purchase.product.category : selected.purchase.service.category}
                  </p>
                  <p className='text-sm font-medium'>
                    {selected.purchase.product ? selected.purchase.product.name : selected.purchase.service.name}
                  </p>
                </div>
                <div className='flex flex-col gap-x-0.5 text-end'>
                  <p className='font-semibold'>{t('request.number')}</p>
                  <p className='text-sm font-medium'>#{selected.identifier}</p>
                </div>
              </div>
              <div className='flex justify-between items-end pt-2.5 gap-0.5'>
                <div className='flex flex-col gap-x-0.5 text-zinc-400'>
                  <p className='text-xs'>{t('request.change-at')}</p>
                  <p className='text-xs'>
                    {selected.changeAt
                      ? moment(selected.changeAt, 'DD-MM-YYYY HH:mm').format('D MMMM YYYY HH:mm')
                      : moment(selected.createdAt, 'DD-MM-YYYY HH:mm').format('D MMMM YYYY HH:mm')
                    }
                  </p>
                </div>
                <div className='flex flex-col gap-x-0.5 shrink-0 text-end'>
                  <Badge className={cn("text-white", stateColors[selected.state])}>
                    {t(`request.states.${selected.state}`)}
                  </Badge>
                </div>
              </div>
            </div>
          </Card>

          <Card className='flex flex-col gap-4 rounded-lg mt-6 space-y-4'>
            <div className='py-4 px-1.5 flex flex-col gap-3'>
              <Subtitle title={t('request.process')} text={t('request.reviewLogs')} />
              <div className='flex flex-col gap-2 mt-1'>
                {selected.logs.map((log, index) => (
                  <React.Fragment key={index}>
                    <div className='flex justify-between'>
                      <div className='w-[62%]'>
                        <span className='text-xs'>{moment(log.createdAt, 'DD-MM-YYYY HH:mm').format('D MMMM YYYY HH:mm')}</span>
                      </div>
                      <div className='w-[38%] flex justify-end items-center'>
                        <Badge className={cn("text-white", stateColors[log.action])}>
                          {t(`request.states.${log.action}`)}
                        </Badge>
                      </div>
                    </div>
                    {selected.logs.length !== index + 1 && <div className='w-full border-t border-zinc-100' />}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </Card>

          <Card className='flex flex-col gap-4 rounded-lg mt-6 space-y-4'>
            <div className='p-4 flex flex-col gap-3'>
              <Subtitle title={t('general.actions')} />
              <div className='grid grid-cols-1 gap-3'>
                <Button
                  isDisabled={selected.state !== 2}
                  size='sm'
                  text={t('locker.openForDeposit')}
                  onClick={openLocker}
                />
                <Button
                  isDisabled={selected.state !== 2}
                  size='sm'
                  color='danger'
                  text={t('locker.cancel')}
                  onClick={handleCancelRequest}
                />
              </div>
            </div>
          </Card>
        </div>
      }
    </div>
  )
}

const PageSkeleton = () => {
  return (
    <div className='mb-4 overflow-y-auto'>
      <Card className='flex flex-col gap-4 rounded-lg mt-6 space-y-4'>
        <div className='p-4'>
          <div className='flex flex-col gap-1.5'>
            <div className='w-1/3 bg-slate-100 h-4 rounded-md animate-pulse' />
            <div className='w-2/3 bg-slate-100 h-4 rounded-md animate-pulse' />
          </div>
          <div className='flex justify-between pt-4'>
            <div className='flex flex-col gap-x-0.5'>
              <p className='font-semibold'>
              </p>
              <p className='text-sm font-medium'>
              </p>
            </div>
            <div className='flex flex-col gap-0.5 text-end flex-wrap-reverse'>
              <div className='w-20 bg-slate-100 h-4 rounded-md animate-pulse' />
              <div className='w-10 bg-slate-100 h-4 rounded-md animate-pulse' />
            </div>
          </div>
          <div className='flex justify-between items-end pt-2.5 gap-0.5'>
            <div className='flex flex-col gap-x-0.5 text-zinc-400'>
              <div className='w-32 bg-slate-100 rounded-md h-4 animate-pulse' />
            </div>
            <div className='flex flex-col gap-x-0.5 text-end max-w-32 overflow-hidden'>
              <div className='w-10 bg-slate-100 rounded-md h-4 animate-pulse' />
            </div>
          </div>
        </div>
      </Card>

      <Card className='flex flex-col gap-4 rounded-lg mt-6 space-y-4'>
        <div className='p-4 flex flex-col gap-3'>
          <div className='w-2/3 bg-slate-100 h-4 rounded-md animate-pulse' />
          <div className='w-4/5 bg-slate-100 h-4 rounded-md animate-pulse' />
          <div className='flex flex-col gap-2'>
            {Array.from({ length: 3 }).map((log, index) => (
              <React.Fragment key={index}>
                <div className='flex justify-between gap-2'>
                  <div className='w-3/5 bg-slate-100 rounded-md h-4 animate-pulse' />
                  <div className='w-2/5 flex justify-end items-center rounded-md h-4 bg-slate-100 animate-pulse' />
                </div>
                {3 !== index + 1 && <div className='w-full border-t border-zinc-100' />}
              </React.Fragment>
            ))}
          </div>
        </div>
      </Card>

      <Card className='flex flex-col gap-4 rounded-lg mt-6 mb-6 space-y-4'>
        <div className='p-4 flex flex-col gap-3'>
          <div className='flex flex-col gap-1.5'>
            <div className='w-1/3 bg-slate-100 h-4 rounded-md animate-pulse' />
            <div className='w-2/3 bg-slate-100 h-4 rounded-md animate-pulse' />
          </div>
          <div className='grid grid-cols-1 gap-3'>
            <div className='w-full bg-slate-100 h-6 rounded-md animate-pulse' />
            <div className='w-full bg-slate-100 h-6 rounded-md animate-pulse' />
          </div>
        </div>
      </Card>
    </div>
  )
}

export default RequestDetailsPage;