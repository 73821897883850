import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from 'helpers/api'
import { toast } from "react-toastify";
import { t } from 'i18next';

export const getPurchases = createAsyncThunk('purchase/list', async (params, { dispatch }) => {
  try {
    const response = await api.get("screen02/user/purchases/" + params);
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

export const purchaseDetails = createAsyncThunk('purchase/details', async (params, { dispatch }) => {
  try {
    const response = await api.get("screen02/purchase/details/" + params);
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

const initialState = {
  list: false,
  selected: false,
  id: false,
  modal: false,
  error: false,
};

export const purchaseSlice = createSlice({
  name: "purchase",
  initialState,
  reducers: {
    selectPurchase(state, action) {
      state.id = action.payload;
    },
    updateLocation(state, action) {
      state.regist.location = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getPurchases.fulfilled, (state, action) => {
      state.list = action.payload.Data
    })
    builder.addCase(purchaseDetails.fulfilled, (state, action) => {
      state.selected = action.payload.Data
    })
  }
});

export const { selectPurchase, updateLocation } = purchaseSlice.actions;

export default purchaseSlice.reducer;
