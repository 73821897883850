import React from 'react';
import { formatPrice } from 'hooks/format-price';
import { useTranslation } from 'react-i18next';
import Icon from 'components/ui/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { addItemToCart, removeItemFromCart } from 'store/cart';
import cn from 'classnames';

const ProductCard = ({ product, category }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { items } = useSelector(state => state.cart);

  const calcValue = (quantity, total) => {
    let value = (total / quantity);

    return formatPrice(value);
  }

  const check = (product) => {
    if (items.find(item => (item.id === product.id && item.type === 'product'))) {
      return false;
    } else {
      return true;
    }
  }

  const add = (product) => {
    if (check(product)) {
      dispatch(addItemToCart({ ...product, type: 'product', category: category.name }))
    }
  }

  const remove = (product) => {
    if (!check(product)) {
      dispatch(removeItemFromCart({ id: product.id, quantityToRemove: items.find(item => item.id === product.id).quantity, type: 'product' }))
    }
  }

  const Quantity = () => (
    <div className="flex gap-3.5 w-full justify-end">
      <div
        className={cn(
          'h-5 w-5 rounded-full flex justify-center items-center border',
          {
            'border-black': !check(product),
            'border-gray-300': check(product)
          }
        )}
        onClick={() => remove(product)}
      >
        <Icon
          icon="lucide:minus"
          width="14"
          className={cn(
            {
              'text-black': !check(product),
              'text-gray-300': check(product)
            }
          )}
        />
      </div>
      <div className="w-2.5 flex justify-center">
        {items.find(item => item.id === product.id && item.type === 'product') ? '1' : '0'}
      </div>
      <div
        className={cn(
          'h-5 w-5 rounded-full border flex justify-center items-center',
          {
            'bg-black border-black': check(product),
            'bg-gray-300 border-gray-300': !check(product)
          }
        )}
        onClick={() => add(product)}
      >
        <Icon icon="lucide:plus" width="14" className="text-white" />
      </div>
    </div>
  )

  return (
    <div className="flex gap-5">
      <div className="w-[20%]">
        <div className="h-12 w-12 bg-zinc-100 flex justify-center items-center rounded-lg">
          <img src="/icons/board.svg" alt="lemon" width="22" />
        </div>
      </div>
      <div className="w-[50%] flex flex-col justify-between py-1">
        <span className="text-xs font-semibold">
          {product.name}
        </span>
        <span className="text-xs text-secondary lowercase">
          {calcValue(product.quantity, product.price)} {t('general.by-piece')}
        </span>
      </div>
      <div className="w-[30%] flex flex-col justify-between items-end py-0.5">
        <span className="text-xs font-semibold">
          {formatPrice(product.price)}
        </span>
        <Quantity />
      </div>
    </div>
  )
}

export default ProductCard;