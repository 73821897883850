import React, { useState } from "react";
import Icon from "./Icon";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.us";

const Input = ({
  type,
  label,
  placeholder = "Add placeholder",
  classLabel = "form-label",
  className = "",
  classGroup = "",
  register,
  name,
  readonly,
  value,
  error,
  icon,
  disabled,
  id,
  horizontal,
  validate,
  isMask,
  msgTooltip,
  description,
  hasicon,
  onChange,
  options,
  onFocus,
  defaultValue,
  noMsgError,
  bordered = false,
  ...rest
}) => {

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div
      className={`fromGroup w-full ${horizontal ? "flex" : ""
        }  ${validate ? "is-valid" : ""} `}
    >
      {label && (
        <label
          htmlFor={id}
          className={`text-black block capitalize ${classLabel}  ${horizontal ? "flex-0 mr-6 md:w-[100px] w-[60px] break-words" : ""
            }`}
        >
          {label}
        </label>
      )}
      <div className={`relative ${horizontal ? "flex-1" : ""}`}>
        {name && !isMask && (
          <input
            type={type === "password" && open === true ? "text" : type}
            {...register(name)}
            {...rest}
            className={`form-control py-2.5 ${bordered && 'border border-b-black border-white'} ${className} ${icon ? 'pl-8' : ''}  `}
            placeholder={placeholder}
            readOnly={readonly}
            defaultValue={defaultValue}
            disabled={disabled}
            id={id}
            onChange={onChange}
            autoComplete={name}
          />
        )}
        {!name && !isMask && (
          <input
            type={type === "password" && open === true ? "text" : type}
            className={`form-control py-2 ${className} ${icon ? 'pl-8' : ''}`}
            placeholder={placeholder}
            readOnly={readonly}
            disabled={disabled}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            id={id}
            autoComplete={id}
          />
        )}
        {name && isMask && (
          <Cleave
            {...register(name)}
            {...rest}
            placeholder={placeholder}
            options={options}
            className={`${error ? " has-error" : " "
              } form-control py-2 ${className}  `}
            onFocus={onFocus}
            id={id}
            readOnly={readonly}
            disabled={disabled}
            onChange={onChange}
          />
        )}
        {!name && isMask && (
          <Cleave
            placeholder={placeholder}
            options={options}
            className={`${error ? " has-error" : " "
              } form-control py-2 ${className}  `}
            onFocus={onFocus}
            id={id}
            readOnly={readonly}
            disabled={disabled}
            onChange={onChange}
          />
        )}
        {/* icon */}
        {icon &&
          <div className="flex text-xl absolute ltr:right-[14px] rtl:left-[14px] top-1/2 -translate-y-1/2 left-3 space-x-1 rtl:space-x-reverse text-slate-400">
            <span>
              <Icon icon={icon} width="15" />
            </span>
          </div>
        }
        <div className="flex text-xl absolute ltr:right-[14px] rtl:left-[14px] top-1/2 -translate-y-1/2 right-1 space-x-1 rtl:space-x-reverse text-slate-400">
          {hasicon && (
            <span
              className="cursor-pointer"
              onClick={handleOpen}
            >
              {open && type === "password" && (
                <Icon icon="heroicons-outline:eye"/>
              )}
              {!open && type === "password" && (
                <Icon icon="heroicons-outline:eye-off"/>
              )}
            </span>
          )}

          {error && (
            <span className="text-red-500">
              <Icon icon="heroicons-outline:information-circle" />
            </span>
          )}
          {validate && (
            <span className="text-success-500">
              <Icon icon="bi:check-lg" />
            </span>
          )}
        </div>
      </div>
      {/* error and success message*/}
      {!noMsgError && error && (
        <div
          className={` mt-2 ${msgTooltip
            ? " inline-block bg-red-500 text-white text-[10px] px-2 py-1 rounded"
            : " text-red-500 block text-sm"
            }`}
        >
          {error.message}
        </div>
      )}
      {/* validated and success message*/}
      {validate && (
        <div
          className={` mt-2 ${msgTooltip
            ? " inline-block bg-success-500 text-white text-[10px] px-2 py-1 rounded"
            : " text-success-500 block text-sm"
            }`}
        >
          {validate}
        </div>
      )}
      {/* only description */}
      {description && <span className="input-description">{description}</span>}
    </div>
  );
};

export default Input;
