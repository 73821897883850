import axios from 'axios';
import { useDispatch } from 'react-redux';
import { logout } from 'store/auth';

const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const AxiosInterceptor = ({ children }) => {
  const dispatch = useDispatch();

  const publicPaths = [
    'api/category/list',
    'api/service/list',
    'api/product/list',
    'api/place/details',
    'api/place/lockers/sizes/',
  ]

  axiosConfig.interceptors.request.use(
    (config) => {

      const token = window.localStorage.getItem('token');

      const isPublicPath = publicPaths.some(prefix => config.url.startsWith(prefix));

      if (token && !isPublicPath) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axiosConfig.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error?.response?.status === 403) {
        window.location.href = "/403";
      }
      if (error?.response?.data?.code === 401) {
        if (error?.response?.data?.message === "Invalid JWT Token" || error?.response?.data?.message === "Expired JWT Token" || error?.response?.data?.message === "JWT Token not found") {
          window.location.href = "/login";
          dispatch(logout());
        }
      }
      else {
        return Promise.reject(error);
      }
    }
  );

  return children;
}

export default axiosConfig;
export { AxiosInterceptor };
