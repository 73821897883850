import React, { useEffect } from 'react'
import Modal from 'components/ui/Modal'
import { useTranslation } from 'react-i18next'
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import Input from 'components/ui/Input';
import Button from "components/ui/Button";
import { toast } from "react-toastify";
import { newAddress, updateAddress } from "store/address";
import { useForm } from "react-hook-form";

const AddressModal = ({ show, onClose, edit = false, reload, address }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { user } = useSelector(state => state.auth);

  const schema = yup
    .object({
      country: yup.string().required(t('form.required.label')),
      city: yup.string().required(t('form.required.label')),
      zipcode: yup.string().required(t('form.required.label')),
      street: yup.string().required(t('form.required.label')),
    })
    .required();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      address: '',
      country: '',
      city: '',
      street: '',
      zipcode: '',
    },
  });

  const onSubmit = (values) => {
    if (!edit) {
      dispatch(newAddress({ ...values, user: user.id }))
        .then((res) => {
          if (res.payload.Result) {
            onClose()
            reload()
            reset()
            toast.success(t('messages.success.address.created'))
          }
        })
        .catch(() => {
          reload()
        })
    } else {
      dispatch(updateAddress({ ...values }))
        .then((res) => {
          if (res.payload.Result) {
            onClose()
            reload()
            reset()
            toast.success(t('messages.success.address.updated'))
          }
        })
        .catch(() => {
          reload()
        })
    }
  };

  useEffect(() => {
    if (address && edit) {
      setValue('address', address.id);
      setValue('country', address.country);
      setValue('city', address.city);
      setValue('zipcode', address.zipcode);
      setValue('street', address.street);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, edit])

  return (
    <Modal
      centered
      activeModal={show}
      onClose={onClose}
      title={
        <div className="flex gap-2">
          <img src="/icons/map-pin-yellow.svg" alt="lemon" />
          <span>
            {edit ? t('address.edit') : t('address.new')}
          </span>
        </div>
      }
      themeClass="bg-white text-black"
      className="text-black"
    >
      <div className="flex flex-col gap-y-3">
        <Input
          name="country"
          type="text"
          placeholder={t('address.country')}
          label={t('address.country')}
          register={register}
          error={errors.country}
          noMsgError
          bordered
        />
        <Input
          name="street"
          type="text"
          placeholder={t('address.street')}
          label={t('address.street')}
          register={register}
          error={errors.street}
          noMsgError
          bordered
        />
        <Input
          name="city"
          type="text"
          placeholder={t('address.city')}
          label={t('address.city')}
          register={register}
          error={errors.city}
          noMsgError
          bordered
        />
        <Input
          name="zipcode"
          type="string"
          placeholder={t('address.zip-code')}
          label={t('address.zip-code')}
          register={register}
          error={errors.zipcode}
          noMsgError
          bordered
        />
        <div className="flex gap-5 pt-5">
          <Button
            text={t('general.cancel')}
            color="outline"
            onClick={onClose}
          />
          <Button
            text={t('general.save')}
            color="primary"
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </Modal>
  )
}

export default AddressModal