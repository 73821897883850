import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { headers } from 'config/headers';
import Cart from 'components/common/Cart';

import { AnimatePresence, motion } from 'framer-motion';

const Header = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const currentHeader = headers.find(item => item.url === pathname);

  return (
    <div className="w-full flex flex-col justify-center gap-4">
      {currentHeader && (
        <motion.div
          className="text-white w-full flex justify-between items-center">
          <AnimatePresence mode="wait">
            <motion.div
              key={pathname}
              initial={{ x: 30, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -30, opacity: 0 }}
              transition={{ duration: 0.25, type: 'spring', bounce: 0 }}
              className="text-lg flex gap-2 items-center">
              <span className="text-white text-xl font-semibold">
                {t('header.' + currentHeader.key + '.label')}
              </span>
              {currentHeader.icon}
            </motion.div>
          </AnimatePresence>
          <Cart />
        </motion.div>
      )
      }
      {
        currentHeader && (
          <AnimatePresence mode="wait">
            <motion.div
              key={pathname}
              initial={{ x: 30, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -30, opacity: 0 }}
              transition={{ duration: 0.25, type: 'spring', bounce: 0 }}
            >
              <span className="text-white/80 text-xs font-light">
                {t('header.' + currentHeader.key + '.text')}
              </span>
            </motion.div>
          </AnimatePresence>
        )
      }
    </div >
  );
};

export default Header;