// ** Toolkit imports
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import storage from "redux-persist/lib/storage"
import { persistStore, persistReducer } from "redux-persist"

// ** Reducers
import app from './app';
import auth from './auth';
import cart from './cart';
import user from './user';
import service from './service';
import product from './product';
import payment from './payment';
import purchase from './purchase';
import request from './request';
import place from './place';
import address from './address';
import category from './category';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'cart']
}

const rootReducer = combineReducers({
  app,
  auth,
  cart,
  user,
  service,
  product,
  payment,
  purchase,
  request,
  place,
  address,
  category
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
})

const persistor = persistStore(store);

export { store, persistor }