import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from 'helpers/api'
import { toast } from "react-toastify";
import { t } from 'i18next';

export const userDetails = createAsyncThunk('user/details', async (params, { dispatch }) => {
  try {
    const response = await api.get("api/user/details/" + params);
   
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

export const userUpdate = createAsyncThunk('user/userUpdate', async (params, { dispatch }) => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };

  try {
    const response = await api.post("screen02/user/update", params, config);
  
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

const initialState = {
  selected: false,
  list: false
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(userDetails.fulfilled, (state, action) => {
      state.selected = action.payload?.Data
    })
  }
});

export default userSlice.reducer;
