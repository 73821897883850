import React from 'react';
import Icon from 'components/ui/Icon';
import { addItemToCart, removeItemFromCart } from 'store/cart';
import { formatPrice } from 'hooks/format-price';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

// const ServiceCard = ({ service }) => {
//   return (
//     <Card>
//       <div className="py-3 flex items-center gap-5">
//         <div className="w-[15%]">
//           <img src={`/icons/service/${service.icon}.svg`} alt="lemon" />
//         </div>
//         <div className="flex justify-start w-[75%] overflow-hidden">
//           <span className="font-medium text-xs truncate">
//             {service.name}
//           </span>
//         </div>
//         <div className="flex justify-end w-[10%]">
//           <Icon icon="lucide:chevron-right" width="15" />
//         </div>
//       </div>
//     </Card>
//   )
// }

const ServiceCard = ({ service, category }) => {
  const dispatch = useDispatch();

  const { items } = useSelector(state => state.cart);

  const check = (service) => {
    const item = items.find(item => item.id === service.id && item.type === 'service');
    return item ? item.quantity < 10 : true;
  }

  const add = (service) => {
    if (check(service)) {
      dispatch(addItemToCart({ ...service, type: 'service', category: category.name }))
    }
  }

  const remove = (service) => {
    const item = items.find(item => item.id === service.id && item.type === 'service');
    if (item && item.quantity > 0) {
      dispatch(removeItemFromCart({ id: service.id, quantityToRemove: 1, type: 'service' }));
    }
  }

  const Quantity = () => {
    const item = items.find(item => (item.id === service.id && item.type === 'service'));
    const quantity = item ? item.quantity : 0;

    const isRemoveDisabled = quantity <= 0;
    const isAddDisabled = quantity >= 10;

    return (
      <div className="flex gap-3.5 w-full justify-end">
        <div
          className={cn(
            'h-5 w-5 rounded-full flex justify-center items-center border',
            {
              'border-black': !isRemoveDisabled,
              'border-gray-300': isRemoveDisabled,
            }
          )}
          onClick={() => !isRemoveDisabled && remove(service)}
        >
          <Icon
            icon="lucide:minus"
            width="14"
            className={cn(
              {
                'text-black': !isRemoveDisabled,
                'text-gray-300': isRemoveDisabled,
              }
            )}
          />
        </div>

        <div className="w-2.5 flex justify-center">
          {quantity > 0 ? quantity : 0}
        </div>

        <div
          className={cn(
            'h-5 w-5 rounded-full border flex justify-center items-center',
            {
              'bg-black border-black': !isAddDisabled,
              'bg-gray-300 border-gray-300': isAddDisabled,
            }
          )}
          onClick={() => !isAddDisabled && add(service)}
        >
          <Icon icon="lucide:plus" width="14" className="text-white" />
        </div>
      </div>
    )
  }

  return (
    <div className="flex items-center gap-5">
      <div className="w-[20%]">
        <div className="h-12 w-12 bg-zinc-100 flex justify-center items-center rounded-lg">
          <img src="/icons/board.svg" alt="lemon" width="22" />
        </div>
      </div>
      <div className="w-[50%] flex flex-col justify-between py-1">
        <span className="text-xs font-medium">
          {service.name}
        </span>
        <span className="text-xs font-semibold">
          {formatPrice(service.price)}
        </span>
      </div>
      <div className="w-[30%] flex flex-col justify-between items-end py-0.5">
        <Quantity />
      </div>
    </div>
  )
}

export default ServiceCard;