import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { selectPurchase } from 'store/purchase';
import { useDispatch } from 'react-redux';

const PurchaseCard = ({ purchase }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleUse = () => {
    dispatch(selectPurchase(purchase.id))
    navigate('/request/intro')
  }

  return (
    <div className="bg-white w-full rounded-lg rounded-tr-none drop-shadow-lg pt-5 flex flex-col gap-2">
      <div className="px-5 flex gap-4">
        <div className="w-[20%]">
          <div className="bg-zinc-100 h-full w-full flex justify-center items-center rounded-lg relative">
            <div className="absolute -top-1 -end-1 bg-black rounded-full h-6 w-6 flex justify-center items-center">
              <span className="text-white text-[10px] font-semibold">
                {purchase.uses}
              </span>
            </div>
            <img src="/icons/board.svg" alt="lemon" />
          </div>
        </div>
        <div className="w-[80%] flex flex-col gap-1">
          <div className="flex justify-between">
            <span className="text-xs font-semibold pr-4">
              {purchase.product
                ? purchase.product.category
                : purchase.service.category
              }
            </span>
            <div
              className={cn(
                'rounded rounded-tr-none px-2 flex justify-center items-center h-fit py-0.5',
                {
                  'bg-green-600/80': purchase.active,
                  'bg-red-600/80': !purchase.active,
                }
              )}
            >
              <span className="text-white text-xs">
                {purchase.active ? t('general.enabled') : t('general.disabled')}
              </span>

            </div>
          </div>
          <div>
            <span className="text-xs font-medium">
              {purchase.product
                ? purchase.product.name
                : purchase.service.name
              }
            </span>
          </div>
          <div>
            <span className="text-zinc-500 text-xs lowercase">
              {t('purchase.expires-at')} {purchase.expiresAt}
            </span>
          </div>
        </div>
      </div>
      <div className="px-5 py-3">
        <hr className="border border-zinc-100 rounded-lg" />
      </div>
      <div className="text-center pb-3">
        <span className="font-semibold">
          {t('purchase.uses-left', { count: purchase.uses, quantity: purchase.uses })}
        </span>
      </div>
      <div
        className={cn(
          'rounded-lg rounded-tr-none flex justify-center items-center py-3 cursor-pointer',
          {
            'bg-black': purchase.active,
            'bg-zinc-400': !purchase.active
          }
        )}
        onClick={purchase.active ? handleUse : null}
      >
        <span className="text-white font-medium">
          {t('product.use')}
        </span>
      </div>
    </div>
  )
}

export default PurchaseCard;