import React from 'react';
import Input from 'components/ui/Input';
import Button from 'components/ui/Button';
import Card from 'components/ui/Card';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from 'store/auth';
import { updateLoading } from 'store/app';

const Login = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const schema = yup
    .object({
      email: yup.string().required(t('form.required.label')),
      password: yup.string().required(t('form.required.label')),
    })
    .required();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = (values) => {
    dispatch(updateLoading(true))
    dispatch(login(values))
      .then((res) => {
        if (res.payload.Result === 1) {
          navigate('/home')
          reset()
        }
        dispatch(updateLoading(false))
      })
      .catch(() => {
        dispatch(updateLoading(false))
      })
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className="px-8 h-[30%] flex justify-start items-center">
        <img src="/lemon-logo.svg" alt='lemon' />
      </div>
      <div className="bg-white flex-1 rounded-tl-[75px] flex flex-col pt-10 pb-4 px-8 gap-5 min-h-fit h-[70%] overflow-y-auto">
        <span className="text-3xl text-center font-medium">
          {t('auth.login')}
        </span>
        <Card>
          <Input
            name="email"
            type="text"
            placeholder={t('user.email')}
            label={t('user.email')}
            register={register}
            error={errors.email}
            noMsgError
          />
        </Card>
        <Card>
          <Input
            name="password"
            type="password"
            placeholder={t('user.password')}
            label={t('user.password')}
            register={register}
            error={errors.password}
            noMsgError
            hasicon
          />
        </Card>
        <Button
          onClick={handleSubmit(onSubmit)}
          text={t('auth.login')}
        />
        <div className="flex justify-center">
          <span>
            {t('general.or').toLowerCase()}
          </span>
        </div>
        <Button
          text={t('auth.anonymous')}
          color="outline"
          onClick={() => navigate('/home')}
        />
        <div className="flex justify-center items-center gap-x-2 pt-10">
          <span>
            {t('auth.not-have-account')}
          </span>
          <span className="text-black font-normal cursor-pointer underline" onClick={() => navigate('/regist')}>
            {t('auth.make-regist')}
          </span>
        </div>
      </div>
    </div>
  )
}

export default Login;