import React, { useState } from 'react';
import Collapse from 'components/ui/Collapse';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import Icon from 'components/ui/Icon';
import cn from 'classnames';
import sizeDescriptions from 'config/request/size/descriptions';

const SizeCard = ({ size, selected, setSelected }) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);

  const selectedSize = (size) => {
    setSelected(size);
    setExpanded(false);
  }

  const sizeLabel = size.size;

  return (
    <div
      className={cn(
        'bg-white w-full rounded-lg rounded-tr-none shadow-lg overflow-hidden border',
        {
          'border-black': size.size === selected,
          'border-white': size.size !== selected,
        }
      )}
    >
      <div className="flex items-center gap-5 py-3 px-6" onClick={() => setExpanded(!expanded)}>
        <div className="w-[20%]">
          <img src="/icons/locker-block.svg" alt="lemon" />
        </div>
        <div className="w-[70%]">
          <span className="font-semibold">
            {t('locker.size', { size: size.size })}
          </span>
        </div>
        <div className="w-[10%] flex justify-end">
          <motion.span
            animate={{ rotate: expanded ? 90 : 0 }}
            transition={{ duration: 0.2 }}
            className=""
          >
            <Icon icon="lucide:chevron-right" width="15" />
          </motion.span>
        </div>
      </div>
      <Collapse open={expanded} duration={0.2}>
        <div className='p-3'>
          {sizeDescriptions[size.size] &&
            <React.Fragment>
              <p className='text-xs font-medium'>{sizeDescriptions[sizeLabel].description}</p>
              <div className='mt-4 flex flex-col gap-y-3'>
                {sizeDescriptions[sizeLabel].items.map((desc, index) => (
                  <div key={index} className='flex items-center gap-2'>
                    <div className='h-9 w-9 rounded-lg bg-slate-100'>
                      <img src={desc.icon} alt={`Icon for ${desc.description}`} className="w-full h-full object-cover p-2" />
                    </div>
                    <div className='flex flex-col text-xs'>
                      <p className='font-medium'>{desc.title}</p>
                      <p className='text-zinc-400'>{desc.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </React.Fragment>
          }

        </div>
        <div className="mt-auto flex justify-center items-center bg-black py-2.5 rounded-lg rounded-tr-none" onClick={() => selectedSize(size.size)}>
          <span className="text-white text-base">
            {t('product.use')}
          </span>
        </div>
      </Collapse>
    </div>
  )
}

export default SizeCard