import React from 'react';
import { tv } from 'tailwind-variants';
import cn from 'classnames'

const Divider = ({ text, align }) => {
  return (
    <div
      className={cn(
        divider({ align: align }),
      )}
    >
      {text}
    </div>
  )
}

export default Divider;

const divider = tv({
  base: "py-2 flex items-center font-semibold text-lg",
  variants: {
    align: {
      left: 'after:flex-1 after:border-t-2 after:border-black after:ms-6',
      center: 'before:flex-1 before:border-t before:border-zinc-300 before:me-6 after:flex-1 after:border-t after:border-zinc-300 after:ms-6',
      right: 'before:flex-1 before:border-t-2 before:border-black before:me-6',
    },
  },
  defaultVariants: {
    align: "center",
  }
});