import React from 'react'
import Icon from './Icon';
import { tv } from 'tailwind-variants';
import cn from 'classnames'

const Button = ({ color, size, isDisabled = false, outlined = false, onClick, text, icon, iconPositon = 'right', className }) => {

  return (
    <button
      onClick={!isDisabled ? onClick : null}
      className={cn(
        !outlined ? button({ size: size, color: isDisabled ? 'disabled' : color }) : buttonOutlined({ size: size, color: isDisabled ? 'disabled' : color }), className
      )}
    >
      <div className="flex items-center gap-x-2">
        {text &&
          <span className={cn(
            'select-none',
            {
              'order-1': iconPositon === 'right',
              'order-2': iconPositon === 'left',
            }
          )}>
            {text}
          </span>
        }
        {icon &&
          <span className={cn(
            {
              'order-2': iconPositon === 'right',
              'order-1': iconPositon === 'left',
            }
          )}>
            <Icon icon={icon} />
          </span>
        }
      </div>
    </button>
  )
}

export default Button;

const button = tv({
  base: "w-full flex justify-center items-center rounded-lg rounded-tr-none",
  variants: {
    color: {
      black: 'bg-black text-white drop-shadow-lg border border-white/20',
      white: 'bg-white text-black drop-shadow-lg border border-white/20',
      outline: 'bg-white text-black border border-dark',
      disabled: 'bg-black/40 border border-transparent text-slate-200',
      danger: 'bg-red-500/90 border border-transparent text-white',
    },
    size: {
      'xs': 'text-xs py-2 px-1.5',
      'sm': 'text-sm py-2 px-1.5',
      'md': 'text-base py-2.5 px-2',
      'base': 'text-base py-2.5 px-2',
      'lg': 'text-lg py-3',
      'xl': 'text-xl py-3',
    },
  },
  defaultVariants: {
    size: "base",
    color: "black"
  }
});

const buttonOutlined = tv({
  base: "w-full flex justify-center items-center rounded",
  variants: {
    color: {
      primary: 'border border-primary hover:bg-primary/80 text-primary',
      secondary: 'border border-secondary hover:bg-secondary/90 text-secondary',
      success: 'border border-green-500 hover:bg-green-500/90 text-green-500',
      danger: 'border border-red-500 hover:bg-red-400 text-red-500',
      disabled: 'border border-slate-200 text-slate-200',
      white: 'border border-white text-white',
    },
    size: {
      'sm': 'text-sm py-1',
      'md': 'text-base py-2',
      'base': 'text-base py-2',
      'lg': 'text-lg py-3',
      'xl': 'text-xl py-3',
    },
  },
  defaultVariants: {
    size: "base",
    color: "primary"
  }
});
