import React from 'react';
import Button from 'components/ui/Button';
import Card from 'components/ui/Card';
import { useNavigate } from 'react-router-dom';

const ProfileItemCard = ({ icon, to, text }) => {
  const navigate = useNavigate();

  return (
    <Card>
      <div className="flex items-center py-2">
        <div className="w-[20%]">
          <img src={icon} alt="lemon"/>
        </div>
        <div className="w-[65%]">
          <span className="font-semibold text-sm">
            {text}
          </span>
        </div>
        <div className="w-[15%]">
          <Button
            icon="heroicons:arrow-long-right"
            size="xs"
            onClick={() => navigate(to)}
          />
        </div>
      </div>
    </Card>
  )
}

export default ProfileItemCard;