import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'helpers/api'
import { toast } from "react-toastify";
import { t } from 'i18next';

export const regist = createAsyncThunk('auth/regist', async (params, { dispatch }) => {

  const config = { headers: { "Content-Type": "multipart/form-data" } };

  try {
    const response = await api.post("screen02/auth/regist", params, config);
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

export const login = createAsyncThunk('auth/login', async (params, { dispatch }) => {

  const config = { headers: { "Content-Type": "multipart/form-data" } };

  try {
    const response = await api.post("screen02/auth/login", params, config);
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }

    window.localStorage.setItem('token', response.data.Data.token)

    return response?.data;

  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

export const activateAccount = createAsyncThunk('auth/activate', async (params, { dispatch }) => {

  const config = { headers: { "Content-Type": "multipart/form-data" } };

  try {
    const response = await api.post("screen02/auth/activate", params, config);
    // if (!response.data.Result) {
    //   toast.error(t(`api.errors.${response?.data?.Code}`));
    // }

    return response?.data;

  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

export const updateLogged = createAsyncThunk('auth/updateLogged', async (params, { dispatch }) => {
  return params;
})

export const logout = createAsyncThunk('auth/logout', async (params, { dispatch }) => {
  window.localStorage.removeItem('token');
  return false
})

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    logged: false,
    token: false,
    user: false,
  },

  reducers: {},
  extraReducers: builder => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.logged = true
      state.user = action.payload.Data.details
      state.token = action.payload.Data.token
    })
    builder.addCase(updateLogged.fulfilled, (state, action) => {
      state.user = action.payload
    })
    builder.addCase(logout.fulfilled, (state, action) => {
      state.logged = false
      state.user = false
      state.token = false
    })
  }
})

export default authSlice.reducer
