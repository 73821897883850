export const headers = [
  {
    key: 'clothes',
    icon: <span>🍋</span>,
    url: '/home',
  },
  {
    key: 'services',
    icon: <span>🧺</span>,
    url: '/services',
  },
  {
    key: 'products',
    icon: <span>👔</span>,
    url: '/products',
  },
  {
    key: 'profile',
    icon: <span>👤</span>,
    url: '/profile',
  }
];