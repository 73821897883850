import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getRequests } from 'store/request';
import stateColors from 'config/stateColors';
import cn from 'classnames';

import NavMenu from 'components/navigation/NavMenu';
import Badge from 'components/ui/Badge';
import Card from 'components/ui/Card';
import Collapse from 'components/ui/Collapse';
import Icon from 'components/ui/Icon';
import Button from 'components/ui/Button';

import moment from 'moment';
import 'moment/locale/pt'
moment.locale('pt');

const RequestPage = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { user } = useSelector(state => state.auth);
  const { list } = useSelector(state => state.request);

  const fetch = () => {
    dispatch(getRequests(user.id));
  }

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-full pt-10 pb-10 px-6 flex flex-col gap-6">
      <NavMenu label={t('request.label')} text={t('header.request.text')} to="/profile" />
      <div className='flex flex-col gap-4 overflow-y-scroll rounded-lg mt-6 space-y-4'>
        {!list
          ? Array.from({ length: 4 }).map((_, index) => (
            <SkeletonCard key={index} />
          ))
          : list.map((request, index) => (
            <div key={index}>
              <RequestCard request={request} />
            </div>
          ))}
      </div>
    </div>
  )
}

const RequestCard = ({ request }) => {

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);

  return (
    <Card className='!px-0 !py-0 p-0 overflow-hidden'>
      <div className='p-4'>
        <div className='flex justify-between'>
          <div className='flex flex-col gap-x-0.5'>
            <p className='font-semibold'>
              {request.purchase.product
                ? request.purchase.product.category
                : request.purchase.service.category
              }
            </p>
            <p className='text-sm font-medium'>
              {request.purchase.product
                ? request.purchase.product.name
                : request.purchase.service.name
              }
            </p>
          </div>
          <div className='flex flex-col gap-x-0.5 text-end'>
            <p className='font-semibold'>{t('request.number')}</p>
            <p className='text-sm font-medium'>#{request.identifier}</p>
          </div>
        </div>

        <div className='flex justify-between items-end pt-2.5 gap-0.5'>
          <div className='flex flex-col gap-x-0.5 text-zinc-400'>
            <p className='text-xs'>{t('request.change-at')}</p>
            <p className='text-xs'>
              {request.changeAt
                ? moment(request.changeAt, 'DD-MM-YYYY HH:mm').format('D MMMM YYYY HH:mm')
                : moment(request.createdAt, 'DD-MM-YYYY HH:mm').format('D MMMM YYYY HH:mm')
              }
            </p>
          </div>
          <div className='flex flex-col gap-x-0.5 text-end'>
            <Badge className={cn("text-white truncate", stateColors[request.state])}>
              {t(`request.states.${request.state}`)}
            </Badge>
          </div>
        </div>
      </div>
      <div className='w-full bg-black px-4 py-4 flex flex-col text-white rounded-lg rounded-tr-none'>
        <div className='flex justify-between' onClick={() => setExpanded(!expanded)}>
          <p>{t('request.details')}</p>
          <Icon icon='lucide:chevron-right' className={cn('transition-transform', {
            'rotate-90': expanded
          })} />
        </div>
        <Collapse open={expanded} duration={0.2}>
          <div className="bg-black mt-4 rounded-lg rounded-t-none">
            <div className='flex flex-col gap-y-3'>
              <div className='flex justify-between'>
                <p>{t('request.created-at')}:</p>
                <p className='text-xs text-zinc-400'>
                  {request.createdAt && moment(request.createdAt, 'DD-MM-YYYY HH:mm').format('D [de] MMMM YYYY [às] HH:mm')}
                </p>
              </div>
              <div className='flex justify-between'>
                <p>{t('request.change-at')}:</p>
                <p className='text-xs text-zinc-400'>{request.changeAt && moment(request.changeAt, 'DD-MM-YYYY HH:mm').format('D [de] MMMM YYYY [às] HH:mm')}</p>
              </div>
              <div className='flex flex-col gap-1.5'>
                <p>{t('request.delivery.address-label')}:</p>
                <div>

                  <div className='text-xs text-zinc-400'>{request.address
                    ? <div>
                      <p>{request.address.street}</p>
                      <p>{request.address.zipcode} {request.address.city}</p>
                    </div>
                    : <span>{t('general.nonApplicable')}</span>
                  }
                  </div>
                </div>
              </div>

              <Button color='white' text={t('general.seeMore')} onClick={() => navigate('/request/details/' + request.id)} />
            </div>
          </div>
        </Collapse>
      </div>
    </Card>
  )
}

const SkeletonCard = () => {
  return (
    <Card className="!px-0 !py-0 p-0">
      <div className='p-4'>
        <div className='flex justify-between'>
          <div className='flex flex-col gap-0.5'>
            <div className='w-24 h-3 bg-slate-100 rounded-md' />
            <div className='w-20 h-3 bg-slate-100 rounded-md' />
          </div>
          <div className='flex flex-col flex-wrap-reverse gap-0.5 text-end'>
            <div className='w-24 h-3 bg-slate-100 rounded-md' />
            <div className='w-20 h-3 bg-slate-100 rounded-md' />
          </div>
        </div>
        <div className='flex justify-between mt-auto pt-8'>
          <div className='flex flex-col gap-0.5'>
            <div className='w-24 h-3 bg-slate-100 rounded-md' />
          </div>
          <div className='flex gap-0.5 items-end'>
            <div className='w-24 h-3 bg-slate-100 rounded-md' />
          </div>
        </div>
      </div>
      <div className='w-full bg-slate-300 h-8 flex flex-col text-white rounded-lg rounded-tr-none'>
      </div>
    </Card>
  );
};

export default RequestPage;