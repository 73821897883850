import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from 'helpers/api'
import { toast } from "react-toastify";
import { t } from 'i18next';

export const getPlaces = createAsyncThunk('place/list', async (_, { dispatch }) => {
  try {
    const response = await api.get("screen02/place/list");
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

export const placeDetails = createAsyncThunk('place/details', async (params, { dispatch }) => {
  try {
    const response = await api.get("api/place/details/" + params);
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

export const getSizes = createAsyncThunk('place/lockers/sizes', async (params, { dispatch }) => {
  try {
    const response = await api.get("api/place/lockers/sizes/" + params);
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

const initialState = {
  list: false,
  selected: false,
  sizes: false,
};

export const placeSlice = createSlice({
  name: "place",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getPlaces.fulfilled, (state, action) => {
      state.list = action.payload?.Data
    })
    builder.addCase(placeDetails.fulfilled, (state, action) => {
      state.selected = action.payload?.Data
    })
    builder.addCase(getSizes.fulfilled, (state, action) => {
      state.sizes = action.payload?.Data
    })
  }
});

export default placeSlice.reducer;
