import React, { useEffect, useState } from 'react';
import NavMenu from 'components/navigation/NavMenu';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getPurchases, selectPurchase } from 'store/purchase';
import PurchaseCard from 'components/cards/PurchaseCard';
import TabViewer from 'components/ui/TabViewer';
import HeaderCard from 'components/cards/HeaderCard';

const PurchasePage = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { list } = useSelector(state => state.purchase);
  const { user } = useSelector(state => state.auth);

  const [loading, setLoading] = useState(false);

  const [activeTab, setActiveTab] = useState('service');

  const tabItems = [
    {
      value: 'service',
      label: t('service.label'),
      // icon: "lucide:user-round"
    },
    {
      value: 'product',
      label: t('product.label'),
      // icon: "lucide:lock"
    }
  ]

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const tab = queryParams.get("tab");

    if (tab) {
      setActiveTab(tab);
    };
  }, []);

  useEffect(() => {
    dispatch(selectPurchase(false))
    setLoading(true);
    dispatch(getPurchases(user.id))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
    //eslint-disable-next-line
  }, [])

  return (
    <div className="h-full pt-10 px-8 flex flex-col gap-5">
      <NavMenu to="/profile" label={t('purchase.label', { count: 2 })} text={t('purchase.text01')} />
      <div className='h-fit mt-4'>
        <HeaderCard label={t('home.text03')} text={t('home.text04')} icon="/icons/laundry-machine.svg" />
      </div>

      <div className="flex flex-col gap-4 overflow-y-scroll rounded-lg">
        {loading ?
          Array.from({ length: 2 }).map((_, index) => (
            <PurchaseSkeleton key={index} />
          ))
          :
          <>
            <div className='mt-1' />

            <TabViewer
              tabs={tabItems}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />

            {list && list.filter(l => l[activeTab]).map((item, index) =>
              <div key={index}>
                <PurchaseCard purchase={item} />
              </div>
            )}
          </>
        }
      </div>
    </div>
  )
}

const PurchaseSkeleton = () => {
  return (
    <div className="bg-white w-full rounded-lg rounded-tr-none drop-shadow-lg pt-5 flex flex-col gap-2 transition-all">
      <div className="px-5 flex gap-4">
        <div className="w-[20%]">
          <div className="bg-zinc-100 h-full w-full flex justify-center items-center rounded-lg relative">
            <div className='h-14 w-14 bg-slate-100' />
          </div>
        </div>
        <div className="w-[80%] flex flex-col gap-1">
          <div className="flex justify-between gap-5">
            <div className='w-[80%] h-4 bg-slate-100 rounded-md' />
            <div className='w-[20%] h-4 bg-slate-100 rounded-md' />
          </div>
          <div>
            <div className='w-[30%] bg-slate-100 rounded-md h-2 mt-2' />
            <div className='w-[35%] bg-slate-100 rounded-md h-2 mt-2' />
          </div>
        </div>
      </div>
      <div className="px-5 py-3">
        <hr className="border border-zinc-100 rounded-lg" />
      </div>
      <div className="font-semibold m-auto w-1/2 h-2 bg-slate-100" />
      <div className='rounded-lg bg-slate-400 mt-4 h-11' />
    </div >
  )
}

export default PurchasePage;