export const appSettings = {
  name: 'Lemon',
  description: "",
  layout: "vertical-one",
  logo: {
    url: "/lemon-logo.svg",
    alt: "lemon-log",
    href: "/home",
  },
  logoMobile: {
    url: "",
    alt: "",
    href: "/",
    width: 512,
    height: 512,
  },
  defaultLanguage: "pt",
  defaultAvatar: "https://api.dicebear.com/8.x/big-ears-neutral/svg?seed=George",
  author: {
    name: "Purple Profile",
    websiteUrl: "https://purpleprofile.pt",
    address: "",
  },
  countdown: {
    size: '50',
    strokeWidth: '4',
  },
  toast: {
    position: 'bottom-right',
    className: 'bg-white',
    success: {
      className: 'bg-white',
      duration: 5000,
    },
    error: {
      className: 'bg-white',
      duration: 5000
    },
  }
}