import React from 'react';
import Card from 'components/ui/Card';

const HeaderCard = ({ label, text, icon }) => {
  return (
    <Card className="py-5">
      <div className="flex gap-1">
        <div className="w-[25%] flex justify-center items-center">
          <img src={icon} alt="lemon" />
        </div>
        <div className="w-[75%] py-0.5 flex flex-col justify-between">
          <span className="font-semibold">
            {label}
          </span>
          <span className="text-xs text-zinc-400">
            {text}
          </span>
        </div>
      </div>
    </Card>
  )
}

export default HeaderCard