import React, { useEffect, useState } from 'react';
import HeaderCard from 'components/cards/HeaderCard';
import NavMenu from 'components/navigation/NavMenu';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSizes, placeDetails } from 'store/place';
import { updateLoading } from 'store/app';
import Subtitle from 'components/common/Subtitle';
import Button from 'components/ui/Button';
import SizeCard from 'components/cards/SizeCard';
import { createRequest, requestAssignLocker } from 'store/request';

const RequestSize = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { state } = useLocation();

  const { deposit, delivery, location, address } = state;

  const [size, setSize] = useState(false);

  const { id } = useSelector((state) => state.purchase);

  const { selected: place, sizes } = useSelector(state => state.place);

  const getDetails = () => {
    dispatch(updateLoading(true))
    dispatch(placeDetails(state.location))
      .then(() => {
        dispatch(updateLoading(false))
      })
      .catch(() => {
        dispatch(updateLoading(false))
      })
  }

  const fetchSizes = () => {
    dispatch(updateLoading(true))
    dispatch(getSizes(state.location))
      .then(() => {
        dispatch(updateLoading(false))
      })
      .catch(() => {
        dispatch(updateLoading(false))
      })
  }

  const handleConfirm = () => {
    dispatch(updateLoading(true))

    dispatch(createRequest({
      purchase: id,
      deposit: deposit,
      delivery: delivery,
      address: delivery === 'address' ? address : false
    }))
      .then((res) => {
        if (res.payload.Result) {
          dispatch(requestAssignLocker({ request: res.payload.Data.id, size: size, location: location }))
            .then((res) => {
              if (res.payload.Result) {
                navigate('/request/locker', { state: { ...state, request: res.payload.Data, size: size } })
              }
              dispatch(updateLoading(false))
            })
            .catch(() => {
              dispatch(updateLoading(false))
            })
        }
      })
      .catch(() => {
        dispatch(updateLoading(false))
      })
  }

  useEffect(() => {
    if (!id || !location || !delivery || !deposit) {
      navigate('/purchases')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, location, delivery, deposit])

  useEffect(() => {
    getDetails()
    fetchSizes()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, state])

  return (
    <div className="h-full pt-10 pb-10 px-8 flex flex-col gap-6">
      {/* <div> */}
      <NavMenu label={t('request.label')} text={t('header.request.text')} to="/request/qrcode" />
      {/* </div> */}
      <div className="pt-5">
        <HeaderCard
          label={t('request.label') + ' #000000'}
          text={t('request.deposit.your-place', { place: place.name })}
          icon="/icons/map-pin.svg"
        />
      </div>
      <div className='flex flex-col gap-6 overflow-y-auto'>
        <Subtitle title={t('request.size.title')} text={t('request.size.text01')} />
        <div className="flex flex-col gap-6">
          {sizes && sizes.map((item, index) =>
            <div key={index}>
              <SizeCard size={item} selected={size} setSelected={setSize} />
            </div>
          )}
        </div>
        <div className="mt-auto grid grid-cols-2 gap-5">
          <Button
            text={t('general.previous')}
            color="outline"
            onClick={() => navigate('/request/qrcode')}
          />
          <Button
            text={t('general.next')}
            isDisabled={!size}
            onClick={handleConfirm}
          />
        </div>
      </div>
    </div>
  )
}

export default RequestSize;