import React, { useState, useEffect } from 'react';
import HeaderCard from 'components/cards/HeaderCard';
import NavMenu from 'components/navigation/NavMenu';
import Subtitle from 'components/common/Subtitle';
import { useTranslation } from 'react-i18next';
import Button from 'components/ui/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import QRModal from 'components/modals/QRModal';
import { useSelector } from 'react-redux';

const RequestQRCode = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { state } = useLocation();

  const { id } = useSelector(state => state.purchase);

  const [modal, setModal] = useState(false);
  const [location, setLocation] = useState(false);
  const [offline, setOffline] = useState(false);
  const [full, setFull] = useState(false);

  const openModal = () => {
    setModal(true);
  }

  useEffect(() => {
    if (!id) {
      navigate('/purchases');
    }
  }, [id, navigate]);

  return (
    <div className="h-full pt-8 pb-3 px-8 flex flex-col gap-6">
      <QRModal
        show={modal}
        onClose={() => setModal(false)}
        setLocation={setLocation}
        setOffline={setOffline}
        setFull={setFull}
      />
      <div>
        <NavMenu label={t('request.label')} text={t('header.request.text')} to="/request/options" />
      </div>
      <div className='pt-0'>
        <HeaderCard label={t('request.label') + ' #000000'} text={t('request.qrcode.title')} icon="/icons/laundry-signal.svg" />
      </div>

      <div className='pt-0.5 overflow-y-auto flex-1 flex flex-col justify-between gap-6'>
        <div className='space-y-3'>
          <Subtitle title={t('request.qrcode.text01')} text={t('request.qrcode.text02')} />
        </div>
        <div className="flex justify-center items-center">
          <img src="/icons/qrcode.svg" alt="lemon" className='h-40 w-40' />
        </div>

        <div className="space-y-3">
          <Button
            text={t('request.qrcode.read')}
            onClick={openModal}
          />
          <div className="grid grid-cols-2 gap-5">
            <Button
              text={t('general.previous')}
              color="outline"
              onClick={() => navigate('/request/options')}
            />
            <Button
              text={t('general.next')}
              isDisabled={!location || offline || full}
              onClick={() => navigate('/request/size', { state: { ...state, location: location } })}
            />
          </div>
        </div>

      </div>

    </div>
  )
}

export default RequestQRCode;