import { Icon } from "@iconify/react";
import { useEffect, useRef } from "react";

function TabViewer({ tabs, activeTab, setActiveTab }) {
  // const [activeTab, setActiveTab] = useState(tabs[0].name);
  const containerRef = useRef(null);
  const activeTabElementRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    if (activeTab && container) {
      const activeTabElement = activeTabElementRef.current;

      if (activeTabElement) {
        const { offsetLeft, offsetWidth } = activeTabElement;

        const clipLeft = offsetLeft;
        const clipRight = offsetLeft + offsetWidth;
        container.style.clipPath = `inset(0 ${Number(100 - (clipRight / container.offsetWidth) * 100).toFixed()}% 0 ${Number((clipLeft / container.offsetWidth) * 100).toFixed()}% round 17px)`;
      }
    }
  }, [activeTab, activeTabElementRef, containerRef]);

  return (
    <div className="wrapper">
      <ul className="list">
        {tabs.map((tab) => (
          <li key={tab.value}>
            <button
              ref={activeTab === tab.value ? activeTabElementRef : null}
              data-tab={tab.value}
              onClick={() => {
                setActiveTab(tab.value);
              }}
              className="button text-muted-foreground"
            >
              {tab.icon &&
                <Icon icon={tab.icon} />
              }
              {tab.label}
            </button>
          </li>
        ))}
      </ul>

      <div aria-hidden className="clip-path-container" ref={containerRef}>
        <ul className="list bg-black">
          {tabs.map((tab) => (
            <li key={tab.value}>
              <button
                data-tab={tab.value}
                onClick={() => {
                  setActiveTab(tab.value);
                }}
                className="text-white button"
                tabIndex={-1}
              >
                {tab.icon &&
                  <Icon icon={tab.icon} />
                }
                {tab.label}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default TabViewer;